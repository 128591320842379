@import '../utils/variables';
.modal-dialog {
    max-width: 50%;
    &.register-modal .modal-content {
        margin: 3rem 15px !important;
    }
    .modal-content {
        color: $color-onyx;
        padding: 1em;
        border: none;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
        margin: 0 15px;
        .zing-modal-margin {
            margin: -14px;
        }

        .zing-modal-alert-margin {
            @extend .zing-modal-margin;
            height: 380px !important;
        }
        .modal-padding {
            margin: 6px;
            margin-bottom: -2px;
        }
        .header-bottom-border.modal-header {
            border-bottom: 1px solid #dee2e6;
            margin: -4%;
        }

        .modal-header-close {
            #header-close {
                color: $color-black !important;
            }
        }
        .modal-header {
            border: none;
        }

        .msg-body-full-height.modal-body {
            max-height: calc(100vh - 130px);
        }

        .msg-body-padding.modal-body {
            padding-top: 42px !important;
            margin: -4%;
        }
        .msg-body.modal-body {
            padding-top: 32px !important;
            margin: -4%;
        }
        .msg-body-compare.modal-body {
          margin: -4%;
          a {
            color: $color-zing-link;
          }
        }
        .zing-body.modal-body {
            padding: 1.5rem, 1.5rem, 0, 1.5rem !important;
            .zing-label-main {
                font-size: 24px;
                font-family: "Roboto-Regular", sans-serif;
                font-weight: 500 !important;
            }
            .zing-label-secondary {
                font-size: 16px;
                font-family: "Roboto", sans-serif;
            }
            label {
                font-size: 14px;

                .zing-info {
                    font-size: .75rem;
                    font-family: "Roboto", sans-serif;
                }
            }
        }
        .modal-body {
            font-size: 16px;
            .alert-img {
                height: 3em;
                padding: 0.5em 0;
            }
            table:not(.zing-ui) {
                border-collapse: separate;
                border-spacing: 0 0.5em;
                thead {
                    background-color: transparent;
                    color: $color-onyx;
                    th {
                        border: none;
                    }
                }
                tbody {
                    tr {
                        background-color: $color-base;
                        border-radius: 4px;
                        td {
                            .custom-control:not(.zing-ui) {
                                min-height: 3rem;
                                padding-left: 1.5rem;
                            }
                        }
                    }
                }
            }
        }
        .zing-footer.modal-footer {
            margin-top: -38px;
            margin-right: -10px;
            margin-bottom: -20px;
            padding: 1.75rem !important;

            button {
                font-family: "Roboto", sans-serif;
            }
            .btn-primary {
                background-color:$color-zing-btn-blue !important;
                border-color:$color-zing-btn-blue !important;
                &:active, &:hover, &:focus {
                    background-color:$color-zing-btn-dark-blue !important;
                    border-color:$color-zing-btn-dark-blue !important;
                }
            }
        }
        .modal-footer {
            border: none;
        }
    }
}

.modal-dialog-fixed {
    max-width: 40%;
    width: fit-content;
    margin: auto;
}

.full-screen-dialog {
    max-width: 90%;
    width: 90%;
    margin: auto;
}

.modal-fit-content {
    width: max-content;
    margin: auto;
}

.memo-modal-fit-content {
    max-width: fit-content !important;
}

.modal-dialog-40 {
    max-width: 40%;
}

.modal-dialog-25 {
    max-width: 25%;
}

.modal-dialog-30 {
    max-width: 30%;
}

.modal-dialog-500 {
    max-width: 600px;
    .modal-content {
        padding: 24px 32px;
    }
}

.memo-modal {
    .modal-content {
        padding: 0% !important
    }

    .modal-header {
        color: $color-text-10 !important;
        border-bottom: 1px solid $color-checkbox-border !important;
        .close:hover {
          color: $color-black !important;
        }
    }
}

.request-processing {
    max-width: 570px;
}

// overriden styles with default values from zing webkit
.confirmation-modal {
  width: max-content !important;

  .modal-content {
    padding: 0% !important
  }
  .modal-header {
    color: $color-text-10 !important;
    border-bottom: 1px solid $color-checkbox-border !important;
    .close:hover {
      color: $color-black !important;
    }
  }
  .modal-body {
    color: $color-text-10 !important;
    p {
      font-size: .9rem;
      font-weight: 400;
      line-height: 1.5;
    }
  }
}

.modal-dialog-top {
    margin-top: 3rem !important;
}

.modal-dialog-60 {
  width: 60%;
  margin: auto;
  max-width: 480px;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.modal-dialog-85 {
    width: 85%;
    margin: auto;
    max-width: 500px;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

.modal-dialog-70 {
  width: 70%;
  margin: auto;
  max-width: 570px;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.zing-modal-dialog {
    width: 100%;
    margin: auto;
    max-width: 570px !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.zing-modal-alert {
    width: 100%;
    margin: auto;
    max-width: 570px !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.modal-dialog-80 {
    width: 80%;
    margin: auto;
    max-width: 650px;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.modal-dialog-90 {
    margin: 0 auto;
    max-width: 700px;
    margin-top: 1rem;
    &.register-modal {
        max-width: 1200px;
    }
}

.modal-dialog-100 {
    margin: 0 auto;
    max-width: 1000px !important;
}

.modal-dialog-max-70 {
    margin: 0.5rem auto;
    max-width: 70% !important;
    min-width: 70% !important;
}

.modal-dialog-75 {
    margin: 0 auto;
    max-width: 75%;
    width: min-content;
    min-width: 50%;
    margin-top: 1rem;
}

.modal-dialog-max-800 {
    max-width: 800px;
}

.modal-dialog-max-550 {
    max-width: 550px;
}

.modal-dialog-max-1200 {
    max-width: 1200px;
}

.compare-modal-dialog {
    margin: 0 auto;
    max-width: 1116px;
    width: 100%;
}

.lab-compare-modal-dialog {
  margin: 0 auto;
  max-width: 860px;
  width: 100%;
}

.upload-claim-documents-modal {
    margin: 1.75rem auto !important;
}

@media(max-width: 420px) {
    .zing-modal-dialog {
        width: 85%;
        margin-left: 15px;
        margin-right: 15px;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .zing-modal-alert {
        width: 85%;
        margin-left: 15px;
        margin-right: 15px;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

@media(max-width: 991.98px) {
    .modal-dialog-fixed {
        margin-right: 0.7rem !important;
        width: auto;
    }
    .full-screen-dialog {
        width: auto;
    }

    .modal-dialog-top {
       margin-top: 1rem ;
       max-width: 80% !important;
    }
    .modal-dialog, .full-screen-dialog, .modal-dialog-75 {
        max-width: 100%;
    }
    .modal-dialog-90 {
        width: 90%;
        display: flex;
        justify-content: center;
    }
    .modal-title {
      font-size: 18px;
      line-height: 21px;
    }
    .modal-content {
      .modal-body {
        font-size: 14px;
        table:not(.zing-ui) {
					thead {
							display: none;
					}
          td, th {
            padding: 4px 6px;
          }
          tbody {
            tr {
                background-color: transparent !important;
                box-shadow: 0 4px 9px 0 #E6E6E6;
                position: relative;
            }
            td {
                display: inline-block;
                font-size: 16px;
                line-height: 19px;
                color: #615E9B;
                .custom-control:not(.zing-ui) {
                    padding-left: 10px !important;
                    position: absolute;
                    padding-top: 7px;
                }
                .label {
                        color: $color-onyx;
                        font-size: 12px;
                        line-height: 14px;
                }
            }
            td:nth-child(1) {
                display: contents;
            }
            td:nth-child(2) {
                display: block;
                font-size: 20px;
                line-height: 20px;
                color: #615E9B;
                font-family: Roboto-medium;
                padding: 1em 0.5em 1em 2em;
                background-color: #FAFAFC;
            }
            td:nth-child(3) {
                padding: 1em;
            }
            td:nth-child(4) {
                float: right;
                padding: 1em 5em;
            }
            td:nth-child(5) {
                display: block;
                padding: 0 1em 1em;
            }
        }
        }
      }
    }
}

@media(max-width: 1240px) {
    .compare-modal-dialog {
        max-width: 90% !important;
        width: 90% !important;
        margin: auto !important;
    }
}

@media(max-height: 780px) {
    .modal-dialog.register-modal > .modal-content {
        margin-bottom: 8rem !important;
    }
}

.update-address-label {
    color: $color-text-10;
    font-size: 14px;
    line-height: 21px;
}

.update-address-fieldset {
    max-width: fit-content;
}

.select-entered {
    text-align: left;
    margin-top: 10px;
    max-width: fit-content;
}

.personal-details {
    .modal-content {
        .helpdesk-phone {
        white-space: nowrap;
        }
  }
}

.modal-dialog-responsive-30 {
    width: 86%;
    margin: auto;
}
