@import "src/styles/_scss/utils/_variables";

.k-checkbox,
.k-checkbox-label::before,
.k-checkbox-label::after {
  position: absolute;
  left: 6px;
  top: 15px;
  width: 16px;
  height: 16px;
}

.k-checkbox {
  z-index: -1;
  opacity: 0;

  &:checked + .k-checkbox-label {
    &::before {
      color: $notification-blue;
      border-color: $notification-blue !important;
      background-color: $notification-blue;
    }

    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
    }
  }

  &:focus + .k-checkbox-label::before {
    border-color: $color-btn-border;
    box-shadow: 0 0 0 1px $color-white, 0 0 0 0.1rem rgba(70, 99, 143, 0.25);
  }
}

.k-checkbox-label {
  cursor: pointer;

  &::before,
  &::after {
    display: block;
    content: "";
  }
  &::before {
    border-radius: 0.25rem;
    pointer-events: none;
    background-color: $color-white;
    border: $color-checkbox-border solid 1px;
  }
  &::after {
    background: no-repeat 50%/50% 50%;
  }
}
