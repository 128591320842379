@import "../utils/variables";
//Style file for generic side nav bar.
//Mobile side nav- opens on click of burger menu
.sidenav {
  background: linear-gradient(16.65deg, $color-primary 0%, $color-turquoise 130%);
  height: 100%;
  overflow-x: hidden;
  position: fixed;
  left: 0;
  top: 0;
  transition: 0.5s;
  width: 0;
  z-index: 1;
  .nav-container {
    a {
      color: $color-white;
      display: block;
      font-size: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      padding: 1em 0;
      white-space: nowrap;
    }
    .btn-secondary-transparent {
      color: $color-white;
      border-color: $color-white;
      transition: 0.5s;
      margin-top: 100%;
    }
  }
}

.sidenav.active {
  margin-left: 0;
}

.sidenav-common {
  height: 100%;
  overflow-x: hidden;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  margin-left: -40em;
  z-index: 101;
  .nav-container {
    .icon {
      text-align: center;
    }
    a,
    span.navigationItem,
    button.navigationItem {
      color: $hamburger-menu-text;
      display: block;
      font-size: 18px;
      font-family: RobotoCondensed-Regular, "Roboto Condensed", sans-serif;
      padding: 16px 5px;
      white-space: nowrap;
      font-size: 22px;
      line-height: 30px;
    }
    button.navigationItem:focus,
    button.navigationItem:hover {
      box-shadow: none !important;
      text-decoration: none !important;
    }
    .closebtn {
      text-align: center;
      display: inherit;
      position: relative;
      width: 52px;
      margin: 0 auto;
      span {
        color: rgba(0, 0, 0, 0.15);
        vertical-align: middle;
        font-size: 24px;
        position: absolute;
        left: 10px;
        top: 19px;
      }
    }
  }
}

.sidenav-dashboard {
  color: $hamburger-menu-text;
  width: auto;
  left: -450px;
  margin-left: 0;
  width: 100%;
  max-width: 450px;
  background: $hamburger-menu-base;
  font-family: RobotoCondensed-Regular, "Roboto Condensed", sans-serif;
  z-index: 10000;
  -webkit-transition: left 0.5s ease-in-out;
  transition: left 0.5s ease-in-out;
  .close-button {
    font-size: 1.5em;
    cursor: pointer;
  }
  .zing-logo {
    height: 2em;
  }
  .nav-container {
    a {
      color: $hamburger-menu-text;
      text-transform: none;
    }
    .nav-item {
      border-bottom: 1px solid $hamburger-menu-text;
    }
    &:hover,
    &:focus {
      text-decoration: none !important;
    }
    .btn-secondary-transparent {
      margin-top: 0%;
    }
  }
  .accordion {
    width: 100%;
    .card {
      background-color: transparent;
      border: none;
      .card-header {
        border: none;
        padding: 0;
        background-color: transparent;
        button {
          display: inline-flex;
          padding-left: 0px;
          width: 100%;
          &:focus {
            outline: none;
            text-decoration: none;
            border: none;
            border-radius: 0;
            box-shadow: none !important;
          }
          &:hover,
          &:focus {
            text-decoration: none;
          }
          a {
            border: none;
            padding: 12px 0 0 0;
            text-align: left;
            &:hover,
            &:focus {
              text-decoration: none !important;
            }
          }
          &:after {
            width: 10px;
            height: 10px;
            position: absolute;
            color: $hamburger-menu-text;
            right: 10px;
            -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
            border-top: 2px solid;
            border-right: 2px solid;
            content: "";
            margin-top: 20px;
            cursor: pointer;
          }
        }
        button.btn-link.collapsed {
          box-shadow: none;
          &::after {
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
      .card-body {
        padding: 0;
        margin-left: 70px;
        .items {
          border-bottom: 0;
          padding: 0;
          padding-bottom: 0.5em;
        }
        .sub-item {
          font-size: 14px !important;
          padding: 0 0 1em 0;
          opacity: 0.5;
          padding-left: 2em;
          &:hover,
          &:focus {
            opacity: 1;
            text-decoration: none !important;
          }
        }
      }
    }
  }
}

.sidenav-dashboard.active {
  left: 0;
}

@media (max-width: 575.98px) {
  .sidenav-dashboard {
    min-width: 100%;
    left: -100%;
  }
}
