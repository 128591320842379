@import '../utils/_variables.scss';
.tile-checkbox {
    cursor: pointer;
    width: 100%;
    position: relative;
    input {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        opacity: 0;
        &:checked + .tile-style-1 {
            background: linear-gradient(160.79deg, $color-primary 0%, $color-turquoise 100%);
            box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.1);
            .img-col {
                background-color: transparent;
                .blue-img {
                    display: none;
                }
                .white-img {
                    display: inline-block;
                }
            }
            .text-col,
            .number-col {
                color: $color-white;
                font-family: Roboto-Light;
            }
        }
        &:checked + .tile-style-2 {
            .inner-tile {
                background: transparent;
                color: $color-white;
                font-family: Roboto-Light;
                box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.2) !important;
            }
        }
        &:checked + .tile-style-3 {
            .img-col {
                background-color: $color-emerald;
                .blue-img {
                    display: none;
                }
                .white-img {
                    display: inline-block;
                }
            }
        }
        &:focus + .tile-style-1,
        &:focus + .tile-style-2,
        &:focus + .tile-style-3 {
            box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);
        }
    }
}

.tile-style-1, .tile-style-3 {
    border-radius: 4px;
    background-color: $color-white;
    box-shadow: 0 2px 4px 0 #E4DEFE;
    display: flex;
    align-items: center;
    .img-col {
        background-color: #FAFAFC;
        border-radius: 4px 0 0 4px;
        display: inline-block;
        padding: 0.2em;
        height: 5em;
        width: 5em;
        .blue-img {
            display: inline-block;
        }
        .white-img {
            display: none;
        }
    }
    .number-col {
        color: $color-primary;
        font-size: 16px;
        line-height: 14px;
        font-family: Roboto-Medium !important;
    }
    .text-col {
        color: $color-onyx;
        font-size: 14px;
        display: inline-block;
        padding: 1em;
    }
    &.active {
        .img-col {
            background-color: $color-emerald;
        }
    }
}

.tile-style-2 {
    background: none;
    background-image: linear-gradient(to right, #615E9B 0%, #33CCCC 100%);
    border-radius: 40px;
    border: 1px solid transparent;
    border-left-color: $color-primary;
	border-right-color: $color-turquoise;
	overflow: overlay;
    color: $color-primary;
    display: inline-block;
    width: 100%;
    .inner-tile {
        background: $color-white;
        border-radius: 40px;
        display: inline-block;
        padding: 1em;
        font-size: 14px;
        width: 100%;
        text-align: center;
    }
}

.tile-style-3 {
    .img-col {
        padding: 0.5em;
    }
}
.one-page-shipment {
  .packaging-tile-wrap {
      .packaging-tile {
        flex: 0 0 33%;
        max-width: 110px;
        .tile-selector {
            height: 6.5em;
        }
      }
  }
}
.tile {
    input:focus {
        &+ .tile-style-1,
        &+ .tile-style-2,
        &+ .tile-style-3 {
           box-shadow: 0 0 6px 3px rgba(0,0,0,.1)!important;
        }
    }
}
@media(max-width: 991.98px) {
    .tile-style-1 {
        margin-bottom: 1em;
    }
    .cargo-type-tile {
        &:not(:last-of-type) {
            padding-right: 0!important; 
        }
    }
    .packaging-tile {
        &:not(:last-of-type) {
            padding-right: 0!important; 
        }
    }
}

.tile-selector {
    border-radius: 10px;
    background-color: $color-white;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    height: 10em;
    margin-bottom: 2em;
    position: relative;
    width: 100%;
    padding-top: 100%;
    label {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        span {
            background-color: $color-white;
            border-radius: 10px;
            color: $color-onyx;
            cursor: pointer;
            display: block;
            height: 100%;
            padding: 1em 0;
            text-align: center;
            width: 100%;
            justify-content: center;
            flex-direction: column;
            display: flex!important;
            .img-1 {
                height: 4em;
                display: none;
            }
            .img-2 {
                height: 4em;
                display: inline-block;
            }
            p {
                margin-top: 0;
                margin-bottom: 0;
                font-size: 14px;
                line-height: 26px;
                font-family: Roboto-Light;
            }
        }
        input {
            position: absolute;
            width: 100%;
            height: 100%;
            //opacity: 0;
            clip: rect(0,0,0,0);
            z-index: 1;
            cursor: pointer;
            &:focus + span {
                box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);                
            }
            &:checked+span {
                background: linear-gradient(153.65deg, $color-primary 0%, $color-turquoise 100%);
                color: $color-white;
                box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);                
                .plus-sign {
                    display: none;
                }
                .img-1 {
                    display: inline-block;
                }
                .img-2 {
                    display: none;
                }
            }
        }
        .plus-sign {
            border-radius: 50%;
            background-color: $color-primary;
            height: 2em;
            position: absolute;
            right: -10px;
            top: -10px;
            width: 2em;
            &::before {
                color: white;
                content: "+";
                display: flex;
                font-family: serif;
                font-size: 20px;
                justify-content: center;
                margin: 5px auto;
            }
        }
    }
}

@media(max-width: 991.98px) {
    .tile-selector {
        label {
            span {
                padding: 2em 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .plus-sign {
                    display: none;
                }
            }
            p {
                margin-top: 12px;
                font-size: 16px;
            }
        }
    }
    .tile-selector {
        height: 6.5em;
        label {
            span {
                padding: 0.5em 0;
                .img-1,
                .img-2 {
                    height: 3em;
                }
            }
        }
    }
}

