@import "src/styles/_scss/utils/_variables";
@import "./kendo-grid-sort-header";
@import "./kendo-grid-pager";
@import "./kendo-grid-checkbox";

@mixin k-grid-font($font-weight, $font-size: 14px, $color: $color-zing-brown, $line-height: 1.5) {
  font-family: Roboto, sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  color: $color !important;
}

@mixin expanded-border($border-side, $color: $color-table-expanded-border, $border-width: 1px) {
  border-#{$border-side}: $border-width solid $color !important;
}

kendo-grid.labgrown-list {
  .break-spaces {
    white-space: break-spaces;
  }

  td.price {
    font-weight: 700 !important;
    font-family: 'Roboto-Bold' !important;
    color: $color-green-success-message !important;
  }

  td {
    color: $color-text-10 !important;
  }

  td input[type=checkbox] {
    accent-color: $color-text-secondary !important;
  }

  td.grading-lab {
    color: $color-header-blue !important;
    font-family: 'Roboto-Regular' !important;
  }

  .k-grid-table tbody a {
    font-weight: normal;
  }

  .k-grid-content.k-virtual-content {
    height: fit-content;
  }

  .k-grid-header-wrap {
    margin: 0 !important;
  }

  .k-grid-header tr td:first-child {
    padding-left: 0 !important;
  }

  .k-grid-header tr td:first-child {
    padding-right: 0 !important;
  }

  .k-grid-header th,
  .k-grid-table td {
    border-top: 2px solid $color-table-border !important;
  }

  .k-grid-header thead .k-link {
    color: $color-text-10 !important;
  }

  tr {
    border-bottom: 2px solid $color-table-border;
  }

  .k-grid-table {
    color: $color-header-blue;
  }

  .k-grid-table tbody td {
    padding: 0 0.5rem !important;
  }


  .k-grid-aria-root {
    height: auto !important;

    .k-grid-content {
      height: auto !important;
    }
  }

  kendo-grid-list {
    .k-grid-content-locked {
      height: fit-content;
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 16px;
  }

  ::-webkit-scrollbar-track {
    background: $color-white;
  }
}

kendo-grid.marketplace-grid .k-grid-aria-root {
  height: auto !important;
}

kendo-grid.jmcc-dashboard-grid .k-grid-aria-root {
  td {
    text-wrap: wrap !important;
  }

  tr {
    height: 62px;
  }

  .k-grid-norecords {
    text-align: center;
  }
}

kendo-grid:not(.supply-order-kendo-grid):not(.memo-grid-details):not(.shipment-quote-grid):not(.scan-from-kendo-grid):not(.reconciliation-grid):not(.jmcc-dashboard-grid):not(.jmcc-dashboard-grid-expanded):not(.paid-statements-grid):not(.statement-details-grid):not(.transactions-listing-grid) .k-grid-aria-root {
  height: 33.3rem;

  .k-grid-content {
    height: 30rem;
  }

  .k-grid-header-wrap {
    margin-right: 17px;
  }
}

kendo-grid.shipment-quote-grid,
kendo-grid.memo-grid-details {
  .k-grid-content.k-virtual-content {
    height: fit-content;
  }

  kendo-grid-list {
    &.k-grid-container {
      max-height: 33.3rem;
      overflow: auto;
    }

    .k-grid-content-locked {
      height: fit-content;
    }

  }
}

// supply order grid specific css
kendo-grid.supply-order-kendo-grid {
  kendo-pager {
    flex-direction: row;
  }
}

.k-grid-content {
  overflow: auto;
}

.k-grid-aria-root {
  .k-grid-header-wrap {
    overflow: hidden;
  }

  .k-grid-table-wrap table tbody td {
    height: 48px !important;
    padding: 0 10px !important;
  }
}

.order-now-column {

  .buy-button,
  .add-to-cart-button {
    height: 1.5rem !important;
    margin: auto !important;
    line-height: 0.6rem !important;
  }

  .buy-button {
    width: 2.5rem !important;
  }

  .add-to-cart-button {
    width: 8.5rem !important;
    border-radius: .15rem;
  }
}

// shipment reconciliation grid specific css
kendo-grid.reconciliation-grid {
  .k-grid-aria-root {
    height: auto !important;
    overflow: visible;
  }

  .k-grid-header {
    position: sticky;
    top: 0;
    z-index: 99;
    background: $color-white;
  }


  .k-grid-table td {
    white-space: pre-wrap !important;
    word-wrap: normal !important;

    a {
      color: $color-header-blue;
    }
  }

  .k-grid-header-wrap {
    margin-right: 0;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: $color-white;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $color-light-gray2 !important;
  }

  kendo-grid-filter-menu {
    a {
      margin-right: .3rem !important;
      float: left !important;
    }
  }

}

kendo-grid.transactions-listing-grid,
kendo-grid.paid-statements-grid {

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $color-light-gray2 !important;
  }

  .k-grid-aria-root .k-grid-table td {
    white-space: normal;
  }

  .k-grid-header th.alignRight {
    text-align: right;
  }

  .k-grid-table td.alignRight {
    text-align: right;
  }

  .k-grid-header th.alignCenter {
    text-align: center;
  }

  .k-grid-table td.alignCenter {
    text-align: center;
  }

  .k-grid-table td.recipient {
    padding: .25rem 0 !important;
  }

  .k-grid-footer {
    padding-right: 0.5rem !important;

    .k-grid-footer-wrap table tr td {
      width: 100%;
      padding-left: 0rem !important;
    }
  }

  td.k-detail-cell {
    border-right: 1px solid $color-table-border !important;
  }

  tr:not(.k-expanded-row) {
    td.statement-number {
      color: $color-header-blue !important;
    }
  }
}

.k-grid-header table,
.k-grid-footer table {
  table-layout: fixed;
  overflow: hidden;
}

.k-grid-content table,
.k-grid-content-locked table {
  table-layout: fixed;
  overflow: hidden;
}

.k-grid .k-hierarchy-col,
.k-grid .k-hierarchy-cell {
  width: 0;
  padding: 0px !important;
  overflow: hidden;
}

.k-grid-content table {
  margin: 0;
  width: 100%;
  max-width: none;
  border-collapse: collapse;
  empty-cells: show;
}

.k-grid-header thead,
.k-grid-header thead .k-link {
  @include k-grid-font(bold, 12px);
  opacity: 1;

  &:focus {
    outline: none;
  }
}

.k-grid-header thead .k-link {
  cursor: pointer;
}

.k-grid-table td {
  @include k-grid-font(400);
  border-bottom: 0 !important;
}

.k-grid-header th,
.k-grid-table td {
  position: relative;
  padding: 17px 10px !important;
  background-color: transparent !important;
  box-shadow: none !important;
  white-space: nowrap;
  text-transform: none !important;

  border-top: 1px solid $color-table-border !important;
}

.k-grid-header th {
  border-bottom: 1px solid $color-table-border !important;
}

.k-grid-table td {
  &.estimated-total-price {
    @include k-grid-font(bold, 14px, $color-strong-yellow-green);
  }
}

.k-grid-table tr.k-expanded-row td {
  @include expanded-border("bottom", $color-table-border);
}

.k-grid-table tr.k-detail-row td:not(.z-ui-table) {
  border-top: 0 !important;
}

.btn-icon {
  border: none;
  padding: 0px !important;
  background-color: transparent;
  font-size: 20px !important;
  color: $color-icon-blue;
}

td.order-now-column {
  padding: 0px !important;
  text-align: center;
  padding: 0 !important;
}
.total-price {
  @include k-grid-font(bold, 14px, $color-strong-yellow-green);
}

.k-grid-table td.k-expand-column {
  .k-expand-checkbox {
    position: absolute;
    opacity: 0;
    z-index: -1;

    +label {
      .checked {
        display: none;
      }

      .unchecked {
        display: inline-block;
      }
    }

    &:checked+label {
      .checked {
        display: inline-block;
      }

      .unchecked {
        display: none;
      }
    }
  }

  label {
    display: block;
    margin: 0px !important;
    text-align: center;
    cursor: pointer;

    i {
      font-weight: 600 !important;
    }
  }
}

.k-grid-table td {
  &.total-price {
    @include k-grid-font(bold, 14px, $color-strong-yellow-green);
  }
}

.k-detail-row {
  height: 100px !important;
}

kendo-grid.scan-from-kendo-grid.import-contact-grid .k-grid-aria-root .k-grid-header th {
  white-space: nowrap;
}

kendo-grid.scan-from-kendo-grid.import-contact-grid .k-grid-aria-root .k-grid-content {
  height: auto;
  max-height: 30rem;
}

kendo-grid.memo-dashboard-grid {
  .k-grid-content.k-virtual-content {
    height: fit-content;
  }

  .k-grid-table {
    tbody {
      border-bottom: 2px solid $color-box-shadow;

      td {
        border-bottom: none !important;

        a {
          color: $color-header-blue;
        }
      }
    }
  }

  .k-grid-header {
    tr {
      height: 72px !important;
    }
  }

  .k-grid-aria-root {
    height: auto !important;

    .k-grid-content {
      height: auto !important;
    }

    .k-grid-header-wrap {
      margin-right: 50px !important;
    }
  }

  kendo-grid-list {
    .k-grid-content-locked {
      height: fit-content;
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 16px;
  }

  ::-webkit-scrollbar-track {
    background: $color-white;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $color-light-gray2 !important;
    border-top: 7px solid $color-scrollbar-border;

    background-clip: padding-box;
  }
}

.jmcc-dashboard-grid {
  .k-grid-table td {
    border-bottom: 1px solid $color-table-border !important;
  }
}

kendo-grid.jmcc-dashboard-grid-expanded .k-grid-aria-root {
  tr {
    height: auto !important;
  }

  .k-grid-table-wrap table tbody td {
    text-wrap: wrap !important;
    height: 100% !important;
    align-content: baseline;
  }
}

.jmcc-dashboard-expand {
  .k-grid-header th,
  .k-grid-table td {
    border-top: none !important;
    border-bottom: none !important;
    height: 100% !important;
  }
}

.jmcc-dashboard-expand {
  kendo-grid:not(.supply-order-kendo-grid):not(.memo-grid-details):not(.shipment-quote-grid):not(.scan-from-kendo-grid):not(.reconciliation-grid):not(.jmcc-dashboard-grid):not(.paid-statements-grid):not(.statement-details-grid):not(.transactions-listing-grid) .k-grid-aria-root {
    height: auto;

    .k-grid-content {
      height: auto;
    }
  }
}

