@import "src/styles/_scss/utils/_variables";

z-shipment-reconciliations-listing {
  kendo-popup {
    min-width: 265px;

    .k-filter-menu .k-filter-menu-container {
      background-color: $color-white;
    }
    
    .k-action-buttons {
      display: flex;
      justify-content: flex-end;
      border-width: 0px;
    }
    
    .k-filter-menu .k-action-buttons .k-button {
      border-radius: 50px;
      border-width: 1px;
      border-style: solid;
      border-color: $color-zing-bar;
      margin-bottom: 10px;
      margin-right: 10px;
      width: 90px;
      height: 40px;
      padding: 10px;
      flex: initial;
      &.k-primary {
        margin-left: 0px;
        margin-right: 10px;
      }
    }
  }
}
