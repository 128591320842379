@import "src/styles/_scss/utils/_variables.scss";

// modal backdrop
body.modal-open.zing-ui-modal-backdrop {
  .modal-backdrop {
    background-color: $color-black;
    &.show,
    &.fade {
      opacity: 0.65;
    }
  }
  .member-layout {
    filter: none;
  }
}

.tnc-body {

  .text-indent-4rem {
    text-indent: 4rem;
  }
  
  .pa-list-unstyled {
    padding-left: 2.5rem;
    list-style: none;
  }
  
  .text-decoration-underline {
    text-decoration: underline;
  }

  div > h3 {
    margin-bottom: 1.5rem;
  }
}

// default webkit styles
.zing-ui-modal {
  &.modal-dialog {
    .modal-header {
      border-bottom: 1px solid $color-checkbox-border;
      font-weight: 700;
      color: $color-text-10;

      .close {
        &:hover,
        &:focus {
          color: $color-black;
        }

        &:focus {
          box-shadow: none !important;
          outline: 1 !important;
        }
      }
    }

    .modal-content {
      padding: 0;
      box-shadow: none;
      margin: 0;

      p {
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 1.5;
        color: $color-text-10;
      }
    }

    .modal-footer .btn {
      width: auto !important;
    }
  }

  &.modal-rounded {
    .modal-content {
      border-radius: 8px;
      .modal-body {
        padding: 2rem;
      }
      .modal-header {
        padding: 1rem 2rem;
      }
    }
  }

  @media (min-width: 576px) {
    &.modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto;
    }
  }

  @media (min-width: 992px) {
    &.modal-dialog.modal-lg {
      max-width: 800px;
    }

    &.modal-dialog.modal-sm {
      max-width: 300px;
    }
  }
}
