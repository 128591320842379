.k-header .k-link {
  text-decoration: none !important;
  display: flex;
}

.k-header .k-checkbox-label::before {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.no-sort-indicator {
  .k-header .k-link span {
    &::before,
    &::after {
      display: none;
    }
  }
}

.k-header .k-link span {
  &::before,
  &::after {
    position: absolute;

    font-family: "Material Design Icons";
    font-size: 18px;

    // this is only needed to override global styles from _kendo.scss
    margin-left: 0.3rem !important;
    width: auto !important;
    height: auto !important;
    border: none !important;
  }
  &::before {
    content: "\F360";
    top: 13px;
  }
  &::after {
    content: "\F35D";
    top: 13px;
  }

  &.k-i-sort-desc-sm::before {
    display: block;
  }
  &.k-i-sort-asc-sm::after {
    display: block;
  }
}
