//  Style file for containers and common elements inside a container of a template
@import '../utils/variables';
@import '../utils/fonts';
@import '../utils/mixins';


//heading size and weight

.heading-typeface-50 {
  @include generate-typeface(50px, 58px);
}
.heading-typeface-40 {
  @include generate-typeface(40px, 47px);
}
.heading-typeface-30 {
  @include generate-typeface(30px, 35px);
}
.heading-typeface-26 {
  @include generate-typeface(26px, 30px);
}
.heading-typeface-24 {
  @include generate-typeface(24px, 28px);
}
.heading-typeface-22 {
  @include generate-typeface(22px, 25px);
}
.heading-typeface-20 {
  @include generate-typeface(20px, 24px);
}
.heading-typeface-18 {
  @include generate-typeface(18px, 21px);
}
.heading-typeface-16 {
  @include generate-typeface(16px, 19px);
}
.heading-typeface-14 {
  @include generate-typeface(14px, 16px);
}
.heading-typeface-12 {
  @include generate-typeface(12px, 14px);
}
.heading-typeface-10 {
	@include generate-typeface(10px, 11px);
}

//heading colors

.heading-primary {
	color: $color-primary;
}
.heading-zing-blue {
	color: $color-zing-blue;
}
.heading-onyx {
	color: $color-onyx;
}
.heading-onyx-light {
	color: $color-text-3;
}
.heading-black {
	color: $color-black;
}
.heading-light-gray {
	color: $color-text-4;
}
.heading-withe {
  color: $color-table-even-row;
}

//heading weights 

.heading-bold {
	font-weight: 500;
	font-family: Roboto-Medium;
}
.heading-regular {
	font-weight: 300;
	font-family: Roboto-Regular;
}
.heading-light {
	font-weight: 300;
	font-family: Roboto-Light;
}

//heading sytles used across platform

.heading-50-bold-primary {
	@extend .heading-typeface-50;
	@extend .heading-bold;
	@extend .heading-primary;
}
.heading-50-light-primary {
	@extend .heading-typeface-50;
	@extend .heading-light;
	@extend .heading-primary;
}
.heading-40-bold-primary {
	@extend .heading-typeface-40;
	@extend .heading-bold;
	@extend .heading-primary;
}
.heading-40-light-primary {
	@extend .heading-typeface-40;
	@extend .heading-light;
	@extend .heading-primary;
}
.heading-30-bold-primary {
	@extend .heading-typeface-30;
	@extend .heading-bold;
	@extend .heading-primary;
}
.heading-30-bold-zing-blue {
	@extend .heading-typeface-30;
	@extend .heading-bold;
	@extend .heading-zing-blue;
}
.heading-30-bold-onyx {
	@extend .heading-typeface-30;
	@extend .heading-bold;
	@extend .heading-onyx;
}
.heading-30-regular-primary {
	@extend .heading-typeface-30;
	@extend .heading-regular;
	@extend .heading-primary;
}
.heading-30-light-onyx {
	@extend .heading-typeface-30;
	@extend .heading-light;
	@extend .heading-onyx;
}
.heading-24-bold-primary {
	@extend .heading-typeface-24;
	@extend .heading-bold;
	@extend .heading-primary;
}
.heading-24-bold-onyx {
	@extend .heading-typeface-24;
	@extend .heading-bold;
	@extend .heading-onyx;
}
.heading-24-onyx {
  @extend .heading-typeface-24;
  @extend .heading-onyx;
}
.heading-22-bold-onyx {
	@extend .heading-typeface-22;
	@extend .heading-bold;
	@extend .heading-onyx;
}
.heading-22-bold-primary {
	@extend .heading-typeface-22;
	@extend .heading-bold;
	@extend .heading-primary;
}
.heading-22-onyx {
	@extend .heading-typeface-22;
	@extend .heading-onyx;
}
.heading-20-bold-primary {
	@extend .heading-typeface-20;
	@extend .heading-bold;
	@extend .heading-primary;
}

.heading-20-bold-zing-blue {
	@extend .heading-typeface-20;
	@extend .heading-bold;
	@extend .heading-zing-blue;
}
.heading-20-bold-onyx {
	@extend .heading-typeface-20;
	@extend .heading-bold;
	@extend .heading-onyx;
}
.heading-20-light-onyx {
	@extend .heading-typeface-20;
	@extend .heading-light;
	@extend .heading-onyx;
}
.heading-20-light-withe {
  @extend .heading-typeface-20;
  @extend .heading-light;
  @extend .heading-withe;
}
.heading-18-onyx {
	@extend .heading-typeface-18;
	@extend .heading-onyx;
}
.heading-18-primary {
	@extend .heading-typeface-18;
	@extend .heading-primary;
}
.heading-18-bold-onyx {
	@extend .heading-typeface-18;
	@extend .heading-bold;
	@extend .heading-onyx;
}
.heading-18-bold-primary {
	@extend .heading-typeface-18;
	@extend .heading-bold;
	@extend .heading-primary;
}
.heading-18-bold-black {
	@extend .heading-typeface-18;
	@extend .heading-bold;
	@extend .heading-black;
}
.heading-18-bold-light-gray {
	@extend .heading-typeface-18;
	@extend .heading-bold;
	@extend .heading-light-gray;
}
.heading-16-onyx {
	@extend .heading-typeface-16;
	@extend .heading-onyx;
}
.heading-16-bold-black {
  @extend .heading-typeface-16;
  @extend .heading-bold;
  @extend .heading-black;
}
.heading-16-primary {
	@extend .heading-typeface-16;
	@extend .heading-primary;
}
.heading-16-zing-blue {
	@extend .heading-typeface-16;
	@extend .heading-zing-blue;
}
.heading-16-bold-primary {
	@extend .heading-typeface-16;
	@extend .heading-bold;
	@extend .heading-primary;
}
.heading-16-bold-onyx {
	@extend .heading-typeface-16;
	@extend .heading-bold;
	@extend .heading-onyx;
}
.heading-16-light-onyx {
	@extend .heading-typeface-16;
	@extend .heading-light;
	@extend .heading-onyx;
}
.heading-14-bold-black {
  @extend .heading-typeface-14;
  @extend .heading-bold;
  @extend .heading-black;
}
.heading-14-onyx {
	@extend .heading-typeface-14;
	@extend .heading-onyx;
}
.heading-14-bold-primary {
	@extend .heading-typeface-14;
	@extend .heading-bold;
	@extend .heading-primary;
}
.heading-14-bold-zing-blue {
	@extend .heading-typeface-14;
	@extend .heading-bold;
	@extend .heading-zing-blue;
}
.heading-14-bold-sapphire {
	@extend .heading-typeface-14;
	@extend .heading-bold;
	color: $color-sapphire;
}
.heading-14-bold-onyx {
	@extend .heading-typeface-14;
	@extend .heading-bold;
	@extend .heading-onyx;
}
.heading-14-light-onyx {
	@extend .heading-typeface-14;
	@extend .heading-light;
	@extend .heading-onyx;
}
.heading-14-light-primary {
	@extend .heading-typeface-14;
	@extend .heading-light;
	@extend .heading-primary;
}
.heading-14-light-onyx-light {
	@extend .heading-typeface-14;
	@extend .heading-light;
	@extend .heading-onyx-light;
}
.heading-12-bold-black {
  @extend .heading-typeface-12;
  @extend .heading-bold;
  @extend .heading-black;
}
.heading-12-onyx {
	@extend .heading-typeface-12;
	@extend .heading-onyx;
}
.heading-12-light-onyx {
	@extend .heading-typeface-12;
	@extend .heading-light;
	@extend .heading-onyx;
}
.heading-12-bold-onyx {
	@extend .heading-typeface-12;
	@extend .heading-bold;
	@extend .heading-onyx;
}
.heading-12-light-primary {
	@extend .heading-typeface-12;
	@extend .heading-light;
	@extend .heading-primary;
}
.heading-12-regular-primary {
    @extend .heading-typeface-12;
    @extend .heading-regular;
    @extend .heading-primary;
}
.heading-12-bold-primary {
	@extend .heading-typeface-12;
	@extend .heading-bold;
	@extend .heading-primary;
}
.heading-12-bold-onyx-light {
	@extend .heading-typeface-12;
	@extend .heading-bold;
	@extend .heading-onyx-light;
}
.heading-10-bold-primary {
	@extend .heading-typeface-10;
	@extend .heading-bold;
	@extend .heading-primary;
}

p:not(.zing-ui) {
	@extend .heading-typeface-16;
}

a, a:hover {
	text-decoration: none !important;
	color: $color-primary;
	cursor: pointer!important;
}

.login-redirect, .login-redirect:hover {
    color: $color-white !important;
    text-decoration: underline !important;
}


//responsive headings

@media(max-width: 991.98px){ 
	.heading-30-bold-primary {
		font-size: 20px;
		line-height: 24px;
	}
	
	.heading-30-bold-zing-blue {
		font-size: 20px;
		line-height: 24px;
	}

	.heading-18-onyx {
		font-size: 14px;
		line-height: 16px;
	}

	.heading-20-light-onyx {
		font-size: 14px;
		line-height: 16px;
	}
	p:not(.zing-ui) {
		font-size: 14px;
		line-height: 16px;
	}
}



