@import 'src/styles/_scss/utils/_variables.scss';

.jeweler-pages {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  //TODO: change classes to something that is not global bootstrap class, use custom class
  &.zing-ui .custom-checkbox .custom-control-label {
    color: $color-text-10;
    line-height: 1.25rem !important;
  }

  .form-group {
    margin-bottom: 1.7rem;
    //TODO: change classes to something that is not global bootstrap class, use custom class
    label:not(.custom-control-label) {
      line-height: 20px;
      font-weight: 700;
      margin-bottom: 4px;
      color: $color-text-10;
      font-size: 0.875rem;
    }

    .form-control {
      box-shadow: none;
      color: $color-black;
      font-size: 0.875rem;
  
      &::placeholder {
        color: $color-light-gray;
      }
    }

    .text-muted {
      line-height: 1rem;
      color: $color-text-10 !important;
    }
  }

  .separator {
    border-bottom: 1px solid $color-base-4;
  }
}

.preview-modal {
  .modal-content {
    border-radius: 10px !important;
    padding: 0.375rem !important;

    .modal-header {
      border-bottom: none;
    }
  }
}
.pages-preview-modal{
  .jeweler-pages-publish-url{
    word-break: break-all;
  }
}

.subtitle {
    font-size: 1rem;
    font-weight: 700;
    color: $color-black;
}

z-jeweler-pages-configure .jeweler-pages.zing-ui .ng-select-container {
  background-image: url('/assets/images/jeweler-pages/dropdown-arrow.svg') !important;
}
