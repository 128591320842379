@import "../utils/_variables.scss";
.popover-body {
  background: $color-background-2;
  border-color: #ddecfd;
  border-radius: 4px;
  color: $color-white;
  padding: 17px;
  li {
    color: $color-onyx;
    &:hover,
    &:focus {
      cursor: pointer;
      color: $color-primary;
    }
  }
}

.bs-popover-bottom {
  .popover-arrow::before {
    border-bottom-color: $color-background-2;
  }
}

.bs-popover-bottom {
  .popover-arrow::after {
    border-bottom-color: transparent;
  }
}

.btn-manage-profile {
  font-size: 12px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $color-tertiary;
  color: $color-primary;
  cursor: pointer;
  padding-bottom: 10px;
  text-align: left;
  width: 100%;
  display: block;
}

.btn-logout {
  font-size: 10px;
}

.card + .popover .popover-body,
.add-contact + .popover .popover-body {
  background: $color-white;
  color: $color-text-3;
  font-size: 12px;
  width: 13em;
  label {
    input {
      display: none;
    }
    margin-bottom: 2px;
    cursor: pointer;
    margin-bottom: 4px;
  }
  button {
    color: $color-text-3;
    cursor: pointer;
    border: none;
    background: transparent;
    display: block;
    padding: 0;
    margin-bottom: 4px;
    &:disabled {
      color: $color-text-4;
    }
  }
}

.add-contact + .popover {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.29), 0 0 8px 0 rgba(0, 0, 0, 0.08);
  border: none;
  margin: 1.5em 0em 0 2em;
  .popover-arrow::before {
    border-bottom-color: $color-white;
  }
  .popover-body {
    width: 11em;
    button {
      color: $color-onyx;
      font-size: 14px;
    }
  }
}

.option-menu {
  .menu + .popover {
    .popover-arrow::after {
      border-bottom-color: $color-white;
    }
    .popover-body {
      background: $color-white;
      border: none;
      box-shadow: none;
      color: $color-text-1;
      font-size: 12px;
      padding: 15px;
      div {
        cursor: pointer;
        margin-bottom: 4px;
      }
    }
  }
}

.notification-button + .popover {
  border-radius: 5px;
  border: 1px solid $color-border-1;
  opacity: 0.95;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
  width: 370px;
  max-height: 280px;
  .popover-arrow {
    &::before {
      border-bottom-color: $color-background-1;
    }
  }
  .popover-body {
    background-color: $color-background-1;
    font-family: "Roboto-regular";
    padding: 0.5em;
    max-height: 350px;
    overflow-y: auto;
  }
}
.help-center {
  .help-center-button + .popover {
    width: 275px !important;
  }
}
/*Billing Detail Tab Start*/
.shipmentInfo .popover .popover-body {
  padding: 0 !important;
  width: 220px !important;
}
.shipmentInfo .bs-popover-bottom .popover-arrow::before {
  border-bottom-color: $color-text-3;
}

.select-user {
  .popover {
    margin-top: 0 !important;
    .popover-arrow {
      right: 1px !important;
      top: -7px !important;
    }
  }
}
/*Billing Detail Tab End*/
/*Create New Shipment Start*/
.shipping-details {
  .tooltip-inner {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
    background-color: $color-background-1 !important;
    color: $color-primary;
    font-family: Roboto-Light;
    border-radius: 4px;
    font-size: 11px;
  }
  .tooltip {
    .popover-arrow::before {
      border-bottom-color: $color-background-1 !important;
    }
  }
}
/*Create New Shipment End*/

/* Quote filter popover */
.filter-selector {
  .popover-body {
    min-width: 120px;
    padding: 12px;
    background-color: white;

    ul,
    li,
    .form-group {
      margin: 0;
      padding: 0;
    }

    li:hover,
    li:focus {
      cursor: pointer;
    }

    .form-group:last-of-type label {
      margin-bottom: 0;
    }
  }
}
//Team permission shipment panel info popover
.permission-shipments-label {
  .popover {
    max-width: 325px;
    border-color: $color-border-1;
    .popover-arrow::before {
      border-bottom-color: $color-border-1;
    }
    .popover-body {
      width: 320px;
    }
  }
}

.registerMailPopover {
  z-index: 1060;
  min-width: 500px;
}

.registerMailPopover.zing-ui {
  min-width: 370px;
}

.registerMailPopover .popover-body {
  max-width: 496px;
}

.feinInfoPopup .popover-body {
  background: $color-white;
}

.document-and-forms {
  min-width: 300px !important;
}

.transactionPartiesPopup,
.forbiddenWordsPopup,
.itnPopup,
.eeiPopup,
.informationalPopup,
.informationalPopupQuotes,
.addressEmptyPopup,
.sealNumberPopup,
.openAddressBookPopup {
  max-width: 310px;
  &.partiesRelated {
    @media (max-width: 400px) {
      max-width: 180px;
    }
  }
  .popover-arrow::after {
    border-bottom-color: $color-zing-tooltip-background;
  }
  .popover-body {
    background: $color-zing-tooltip-background;
  }
  .info-body {
    line-height: 1.3;
  }
}

.informationalPopup {
  .popover-arrow::after {
    border-top-color: initial;
  }
}

.informationalPopupQuotes {
  @media(min-width: 992px) {
    .popover-arrow {
      top: 40% !important;
      &::after {
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        border-left-color: $color-onyx;
      }
    }
  } 
  
  @media(max-width: 991px) {
    width: 160px;
  }
}

.openAddressBookPopup .popover-body {
  height: 37px;
  padding: 7px;
}

.addressEmptyPopup, .openAddressBookPopup {
  &.popover {
    .popover-arrow::after {
      border-right-color: initial;
      border-bottom-color: transparent;
    }
  }
}

.needs-approval-shipment,
.tracking-num-popover {
  min-width: 220px;
  .popover-arrow {
    width: 100% !important;
  }
  .popover-arrow::after {
    border-right-color: transparent;
  }
  .popover-body {
    padding: .7rem;
    background: $color-text-1;
    text-align: left;
    color: $color-white !important;
    a {
      color: $color-white !important;
      text-decoration: underline !important;
    }
  }
}

.needs-approval-shipment {
  .popover-arrow {
    left: 0 !important;
  }
  .popover-arrow::after {
    left: 50%;
    border-bottom-color: $color-text-1 !important;
  }
}

.additional-insurance-popover, .shipment-popover {
  @media (max-width: 400px) {
    max-width: 240px;
  }

  max-width: 310px;
  .arrow {
    &::after {
      left: 50%;
      transform: translateX(-50%);
      border-top-color: $color-text-1 !important;
    }
  }

  .arrow::before {
    border-right-color: transparent;
  }

  .popover-arrow::after {
    border-top-color: $color-text-1 !important;
  }

  .popover-body {
    padding: .7rem;
    background: $color-text-1;
    text-align: left;
    color: $color-white !important;
    a {
      color: $color-white !important;
      text-decoration: underline !important;
    }
  }
}

.shipment-popover {
    max-width: 400px !important;
}

.shipment-status {
  .popover-arrow::after {
    border-bottom-color: $color-zing-tooltip-background;
  }
  .popover-body {
    font-size: 14px !important;
    background: $color-zing-tooltip-background;
    text-align: center;
    color: $color-white !important;
    padding-inline: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.tracking-num-popover {
  max-width: 240px;
  .popover-arrow::after {
    border-top-color: $color-text-1 !important;
  }
}

.transactionPartiesPopup {
  .popover-arrow::after {
    border-right-color: $color-zing-tooltip-background;
    border-bottom-color: transparent;
  }
}
@media (max-width: 1446px) {
  .registerMailPopover .registerMailPopover.zing-ui{
    min-width: 250px;
  }
  .registerMailPopover .popover-arrow::after {
    border-bottom-color: $color-background-2;
  }

  .registerMailPopover .popover-arrow::before {
    border-bottom-color: rgba(0, 0, 0, 0.25);
  }
}

//Billing and Payments Section
.delivery-speed-option fieldset,
.packaging-tile-wrap .packaging-tile,
.service-container .options {
  .popover {
    .popover-arrow::after {
      border-bottom-color: $color-border-1;
    }
    .popover-body {
      width: 180px;
      border: 1px solid $color-border-1 !important;
      background-color: $color-background-1;
      .info-wrap {
        color: $color-primary !important;
        font-size: 12px;
        line-height: 17px;
        text-align: center;
      }
    }
  }
}
@media (max-width: 992px) {
  .export-options {
    .bs-popover-bottom .popover-arrow {
      z-index: 5 !important;
      top: -7px !important;
      display: none !important;
    }
    .popover-body {
      border: 1px solid $color-background-2 !important;
      position: relative !important;
      z-index: 1 !important;
      padding: 17px !important;
    }
    .bs-popover-bottom .popover-arrow::before {
      border-bottom-color: $color-background-2 !important;
    }
  }

  //Billing and Payments Section
  .payment-grid-wrap {
    .export-options {
      .mobile-export-img {
        margin-top: 20px;
      }
    }
  }
}
.shipping-details {
  #pop-up-menu + .popover {
    min-width: 230px !important;
    white-space: nowrap;
  }
}

@media (max-width: 610px) {
  .transactionPartiesPopup {
    .popover-arrow::after {
      border-right-color: transparent;
      border-bottom-color: $color-zing-tooltip-background;
    }
  }

  //for tooltips that are close to left edge of screen..
  //reposition the arrow and move tool tip body to right
  //so it doesn't fall off left edge of device screen
  .left-tooltip.zing-ui-tooltip {
    left: 3rem !important;
    .popover-arrow {
      left: calc(15% - 0.08rem);
    }
  }
}

.zing-ui-tooltip {
  .tooltip-inner {
    border-radius: 0.2rem;
    margin: 0;
    padding: 0.4rem 0.8rem;
    outline: 0;
    font-size: 14px;
    line-height: 1.25;
    background-color: $tooltip-gray;
    color: $color-white;
    opacity: 0.9;
  }
  .popover-arrow::before {
    border-width: 0.4rem 0.4rem 0;
    border-top-color: $tooltip-gray;
    opacity: 0.9;
  }
  &.memo-tooltip {
    opacity: 1;
    .tooltip-inner{
      text-align: left;
      opacity: 1 !important;
    }
  }
  &.appraisal-tooltip {
    max-width: 150px;
  }
  &.appraisal-widget-tooltip {
    opacity: 1;
    .tooltip-inner {
      opacity: 1;
    }
  }
}

// remove opacity on quote error tooltip
.delivery-details {
  .quote-details+.tooltip.show {
    opacity: 1 !important;
  }
}

// shipment address book load
.frequent-address-pop {
  margin-top: .3rem !important;
  border: 1px solid $color-base-4;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 $shadow-black;
  color: $color-text-10;
  .popover-arrow {
    display: none !important;
  }
  .popover-body {
    background: $color-white;
    padding: 1rem !important;
  }
}