@import '../utils/_variables.scss';
*:not(.zing-ui) {
    & > .footer {
        margin: 5em 7em 3em;
        color: rgba(74, 74, 74, 0.8);
        padding: 3em 0 0;
        border-top: 1px solid #ddd;
        img {
            padding-left: 2.5em;
        }
        .order-lg-last {
            text-align: right;
        }
        .order-lg-second {
            span {
                text-decoration: underline;
                cursor: pointer;
                padding: 0 5px;
            }
        }
    }
    
    @media (max-width: 991.98px) {
        & > .footer {
            margin: 1em 2em 4em;
            .order-lg-last {
                text-align: center;
                padding-bottom: 2em;
            }
            p {
                text-align: center;
            }
            img {
                padding: 0 1.2em;
            }
        }
    }
}
