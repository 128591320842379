// ----------------------- Typeahead Address search  ---------------------------------
@import '../utils/_variables.scss';

.dropdown-item {
  display: inline-block;
  padding: 15px 15px 15px 20px;
  color: $color-blue;
  font-size: 1em;
  border-bottom: 1px solid #e6e6e6;
  background-position: right center;
  background-size: 30px 30px;
  background-repeat: no-repeat;
  white-space: normal;

  ngb-highlight {
    line-height: 20px;
  }
}

.dropdown-item.active {
  background-color: $color-base-2;
  color: $color-blue;
}

.dropdown-menu {
  background-color: #F7F7FF;
  padding: 0px;
  width: auto;
}

.dropdown-menu-diamond-results.dropdown-menu {
  width: max-content;
}

.address-details-typeahead,
.address-lookup-container {
  .dropdown-menu.show {
    max-height: 400px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-text-4;
      border-radius: 3px;
      cursor: pointer;
      outline: 1px solid $color-text-4;
    }
  }
}

.address-lookup-container {
  .dropdown-menu.show {
    max-height: 250px;
  }
}

.appraisal-customer-address {
  @media (max-width: 1400px) {
    .address-details-typeahead {
      max-width: 100%;
      margin-bottom: 4.5rem !important;
    }

    .dropdown-menu.show {
      min-width: 100% !important;
    }
  }

  .dropdown-menu.show {
    width: 100% !important;
  }
}