@import "../utils/_variables.scss";
.nav-tabs:not(.zing-ui) {
  .nav-link {
    padding: 0.5rem 0;
    border: none;
    margin-right: 1.2rem;
    opacity: 0.5;
    &:hover,
    &:focus {
      border-color: transparent;
    }
    &.active {
      background-color: transparent;
      border-bottom: 3px solid $color-primary;
      opacity: 1;
    }
    &.active:hover,
    &.active:focus {
      border-bottom: 3px solid $color-primary;
    }
  }
}

.marketplace-dashboard, .memo-dashboard {
  .nav.nav-tabs {
    border-bottom: 2px solid rgba(123,123,123,.2);
    width: 100%;
  }
  .nav-link {
    padding: 0.5rem 0.5rem !important;
    margin-right: 1rem !important;
    border: none !important;
    opacity: 1 !important;
    color: $color-zing-brown !important;

    &:hover,
    &:focus {
      border-color: transparent !important;
    }
    &.active {
      background-color: transparent !important;
      border-bottom: 3px solid $color-zing-btn-blue !important;
    }
    &.active:hover,
    &.active:focus {
      border-bottom: 3px solid $color-zing-btn-blue !important;
    }
  }
}

.cl-account-list-wrap > .nav-tabs {
  border-bottom: unset;
  .nav-item {
    z-index: 1;
  }
  .nav-link {
    padding: 0.5rem 0;
    border: none;
    margin-right: 0.5rem;
    opacity: 0.5;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    &:hover,
    &:focus {
      border-color: rgba(0, 0, 0, 0.25);
    }
    &.active {
      background-color: #fff;
      border-bottom: unset;
      opacity: 1;
    }
    &.active:hover,
    &.active:focus {
      border-bottom: unset;
      border-color: rgba(0, 0, 0, 0.25);
    }
  }
}

@media (max-width: 768px) {
  .cl-account-list-wrap > .nav-tabs {
    .nav-item {
        width: 100%;
        text-align: center;
        .nav-link {
          margin-right: 0;
        }
    }
  }
}

.custom-accordion {
  a.collapsed {
    i.accordion-arrow:before {
      content: "\F142";
    }
  }
}

@media (max-width: 991px) {
  .tab-title {
    display: none;
  }
}
