.claims-search-root {
  max-width: 750px;
  margin-left: 100px;
  .content-page {
    padding-top: 3rem !important;
  }
  .form-group label {
    font-size: 14px !important;
  }
}

.claims-root {
  max-width: 1440px;

  .card-body {
    padding: 2rem !important;
  }
  .content-page {
    padding-top: 3rem !important;
  }
  .form-group label {
    font-size: 14px !important;
  }
  .buttons-group {
    justify-content: flex-end;
  }

  .text-red {
    color: $color-zing-red;
  }
  .section-title {
    font-size: 24px;
    font-family: "RobotoCondensed-Bold";
    line-height: 28px;
  }

  .section-separation {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}

@media (max-width: 1080px) {
  .claims-search-root {
    margin-left: auto;
  }
}

