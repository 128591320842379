/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import './styles/_scss/main';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import './styles/_scss/utils/variables.scss';

.ng-select.custom {
  padding-left: 0;
  padding-right: 0;
}
.ng-select-custom ::ng-deep .ng-select-container {
  height: 55px;
  align-content: center;
  .ng-value-container {
    .ng-placeholder {
      position: initial;
      color: $color-placeholder;
      opacity: 1;
    }
  }
}
z-export-details {
  .ng-select-container {
    .ng-value-container {
      padding:0 !important;
    }
  }
}
#tariffCodeSelect {
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
    font-size: 14px !important;
    font-weight: 700 !important;
  }
}

z-inurnance-only-packaging {
  .ng-select-container {
    .ng-value-container {
      min-width: 150px !important;
    }
  }
}

z-valuation {
  .document-title-card {
    .ng-select.ng-select-single .ng-select-container .ng-value-container,
    .ng-select.ng-select-single .ng-select-container .ng-value-container
    .ng-value {
      white-space: normal !important;
    }
  }
}
