// scan from specific css
kendo-grid.scan-from-kendo-grid {
  kendo-pager {
    flex-direction: row;
  }
  .k-grid-aria-root {
    height: auto;
    .k-grid-content {
      height: auto;
    }
    .k-grid-header {
      th {
        white-space: normal;
      }
    }
    .k-grid-table td {
      font-size: 12px;
      white-space: normal;
    }
  }

  .k-header {
    .k-checkbox-label::before {
      top: 15px;
      -webkit-transform: unset;
      -moz-transform: unset;
      -ms-transform: unset;
      transform: unset;
    }
    .k-link span {
      &::after, &::before {
        right: 10px;
      }
    }
  }
}
