// Mixins

// Mixin for generating height and width of elements
@mixin size($w, $h) {
  height: $h;
  width: $w;
}

// Mixin for generating generic headings for different heading styles
@mixin generate-headings($fs, $lh, $fw, $ff: "Roboto-Regular") {
  font-family: $ff;
  font-size: $fs;
  font-weight: $fw;
  line-height: $lh;
}

// Mixin for generating generic headings for different heading styles
@mixin generate-typeface($fs, $lh, $ff: "Roboto-Regular") {
  font-size: $fs;
  line-height: $lh;
  font-family: $ff;
}

// Mixin for generating generic colors for different buttons
@mixin button-color-generator($bgColor, $color, $borderColor, $border) {
  background: $bgColor;
  border: $border;
  border-color: $borderColor;
  color: $color;
}

/* Below mixin generate margin common classes like 
.m-0 {
  margin : 0
}
.m-1 {
  margin: 1em;
}
.mt-4 {
  margin-top: 2em;
} */
@mixin margin-helper($level, $spacing) {
  .m-#{$level} {
    margin: #{$spacing} !important;
  }

  .mx-#{$level} {
    margin-left: #{$spacing} !important;
    margin-right: #{$spacing} !important;
  }

  .my-#{$level} {
    margin-top: #{$spacing} !important;
    margin-bottom: #{$spacing} !important;
  }

  .mt-#{$level} {
    margin-top: #{$spacing} !important;
  }

  .mb-#{$level} {
    margin-bottom: #{$spacing} !important;
  }

  .ml-#{$level} {
    margin-left: #{$spacing} !important;
  }

  .mr-#{$level} {
    margin-right: #{$spacing} !important;
  }
}

/* Below mixin generate padding common classes like 
.p-0 {
  padding : 0
}
.p-1 {
  padding: 1em;
}
.pt-4 {
  padding-top: 2em;
} */
@mixin padding-helper($level, $spacing) {
  .p-#{$level} {
    padding: #{$spacing} !important;
  }

  .px-#{$level} {
    padding-left: #{$spacing} !important;
    padding-right: #{$spacing} !important;
  }

  .py-#{$level} {
    padding-top: #{$spacing} !important;
    padding-bottom: #{$spacing} !important;
  }

  .pt-#{$level} {
    padding-top: #{$spacing} !important;
  }

  .pb-#{$level} {
    padding-bottom: #{$spacing} !important;
  }

  .pl-#{$level} {
    padding-left: #{$spacing} !important;
  }

  .pr-#{$level} {
    padding-right: #{$spacing} !important;
  }
}

// Mixin for drawing radial progress meter of different values
@mixin draw-progress($progress, $color) {
  @if ($progress <=50) {
    .pie {
      .right-side {
        display: none;
      }
    }
  }

  @else {
    .pie {
      clip: rect(auto, auto, auto, auto);

      .right-side {
        transform: rotate(180deg);
      }
    }
  }

  .pie {
    .half-circle {
      border-color: $color;
    }

    .left-side {
      $rotate: $progress * 3.6;
      transform: rotate($rotate + deg);
    }
  }
}

//Payment card color options
@mixin payment-option($option, $bgColor-1, $bgColor-2) {
  .payment {
    .option-#{$option} {
      background: linear-gradient(162.92deg, $bgColor-1 30%, $bgColor-2 100%);
    }
  }
}

@mixin transition-option($styles, $seconds) {
  -webkit-transition: $styles $seconds ease-out;
  -moz-transition: $styles $seconds ease-out;
  -ms-transition: $styles $seconds ease-out;
  -o-transition: $styles $seconds ease-out;
  transition: $styles $seconds ease-out;
}
