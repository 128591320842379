@import '../utils/_variables.scss';

.ngb-tp {
  .ngb-tp-input-container {
    &.ngb-tp-hour, &.ngb-tp-minute {
      width: auto;
      input {
        color: white;
        font-size: 14px;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background-color: $color-zing-btn-blue;
        border: 0;
        outline: 0!important;
        padding: 0;
      }
      .btn.btn-link {
        .chevron {
          color: $color-secondary;
          &:before {
            border-width: .12rem .12rem 0 0;
            height: 5px;
            width: 5px;
          }
        }
      }
    }
  }
  .ngb-tp-meridian {
    .btn {
      height: 32px;
      width: 32px;
      padding: 0;
    }
  }
}