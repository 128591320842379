//  Style file for containers and common elements inside a container of a template
@import '../utils/variables';
@import '../utils/fonts';

  .container:not(.zing-ui)  {
    padding-bottom: 3em;
    max-width: 1180px;
  }
 
	// main container class 
  .main-section {    
    margin: 0 auto;
    padding: 0;
    margin-top: 2em;
  }
  
  // form conatiner class, includes generic heading class specific only to a form
  .form-container {
    background-color: $color-white;
    border-radius: 5px;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.08);
    margin: 0;
    padding: 2em 2.5em;  
  }
  
  //box container
  .box-container {
    background-color: $color-white;
    border-radius: 10px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
  }

  .one-page-shipment .mat-expansion-panel-body {
    padding: 0;
  }

  .reconciliation-summary-page .mat-expansion-panel-body {
    padding-left: 0px;
    padding-bottom: 0px;
  }

.toggle-address-details:checked ~ .accord-body-wrap .full-details {
  display: none;
}

//One Page Shipment 
.one-page-shipment {
  .signature-option-wrap {
      .label-text	{
        display: flex!important;
      }
  }
}

//for preset page service option ui
z-shipping-preset-form {
  .carriers-services {
    .service-container { 
      .options {
        display: flex!important;
        white-space: nowrap!important;
        justify-content: space-between!important;
      }
    }
    .service-container + .service-container {
      padding-left: 15px!important;
    }
  }  
}

//Packaging Multiselect dropdown
#packagingDetailSelect {
  .ng-select-container {
    .ng-value-container {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.new-shipmentpage ng-select.ng-invalid.ng-touched {
  .ng-select-container {
    border-color: $color-ruby;
  }
}

//Text overflow ui Fix
.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

//Go Back Link Common Css
.go-back-link {
  padding: 0.2em 0;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: $color-primary;
  text-decoration: underline;
}

// responsive handliing of screens
@media(max-width: 991.98px){
  .main-section {
    padding: 0 1em;
    margin-top: 1em;
  }

  .form-container {
    padding: 1em;
  }
}