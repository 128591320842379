@import "../../../../../../styles/_scss/utils/_variables.scss";

#policy-dashboard-container {
  .billing-info,
  .cl-info,
  .shipping-insurance-info,
  .transaction-history-info {
    opacity: 1;
    color: $color-text-1;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
  }

  .shipping-insurance-info,
  .transaction-history-info {
    text-align: right;
    margin-top: 15px;
    margin-bottom: 2rem !important;
  }

  .billing-info {
    margin-top: -50px;
    margin-bottom: 0;
    float: right;
  }

  .billing-info, .cl-info {
    span {
      color: $color-primary !important;
    }
  }

  .no-data {
    font-size: 14px;
    line-height: 16px;
    color: $color-onyx;
  }
}

.nav-tabs:not(.zing-ui) .nav-link.billing-tab:not(.active) {
  color: $color-text-1 !important;
  opacity: 0.7 !important;
}

@media (max-width: 991.98px) {
  #policy-dashboard-container {
    .billing-info {
      margin-top: -15px;
      margin-bottom: 15px;
      float: none;
      text-align: right;
    }
  }
}
