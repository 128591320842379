.preview-appraisal {
  .ng2-pdf-viewer-container {
    overflow-x: unset !important;
  }
}

.appraisal-btn {
  width: 11.25rem;
}

.appraisal-section-title {
  @extend .roboto-condensed-light;
  font-size: 2.25rem !important;
  font-weight: normal !important;
  color: $color-zing-btn-blue;
}

.appraisal-icon {
  color: $color-header-blue;
  font-size: 20px;
}

.appraisal-success-message {
  padding-top: 5px;
  margin: auto;
  color: $color-green-success-message;
  font-family: "Roboto-Bold", Roboto-Bold, sans-serif;
}

.appraisal-success-message-icon {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.appraisal-item-description {
  height: 100px !important;
  resize: none !important;
}

.fw-bold {
  font-weight: bold;
}

.dropdown.show>.dropdown-toggle.btn-outline-primary {
  background-color: $color-zing-btn-blue;
  border-color: $color-zing-btn-blue;
}

.appraisal-attachments .ng2-pdf-viewer-container {
  overflow: hidden !important;
}