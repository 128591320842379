@import '../../utils/variables';

z-dashboard-carousel {
  .owl-theme .owl-dots {
    margin-top: 2rem !important;
  }

  .owl-theme .owl-dots .owl-dot span {
    height: 15px !important;
    width: 15px !important;
  }
  
  .owl-theme .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    background: $color-header-blue !important; /*active and on hover color*/
  }

  .owl-theme .owl-dots .owl-dot:not(.active) span {
    background: #D6D6D6 !important; 
  }
}