@import 'src/styles/_scss/utils/_variables';

.roboto-bold {
  font-family: "Roboto-Bold";
}

.roboto {
  font-family: "Roboto";
}

.roboto-regular {
  font-family: "Roboto-Regular", "Roboto", "sans-serif" !important;
}

.roboto-condensed-bold {
  font-family: "RobotoCondensed-Bold";
}

.roboto-condensed-light {
  font-family: "RobotoCondensed-Light";
}

.widgets-basic-text {
  line-height: $widget-text-line-height !important;
  font-size: $widget-text-font-size !important;
  color: $color-text-10 !important;
}