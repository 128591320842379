@import "src/styles/_scss/utils/_variables";

.k-grid-header {
  padding: 0 !important;
  display: block;
  table {
    width: 100%;
    thead {
      background-color: transparent;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 14px;
      opacity: 0.8;
      &,
      a {
        color: $color-onyx;
      }
      th {
        vertical-align: top;
      }
    }
  }

  .k-i-sort-asc-sm,
  .k-i-sort-desc-sm {
    &:after {
      content: "";
      display: inline-block;
      margin-left: 10px;
      width: 0;
      height: 0;
      border-style: solid;
    }
  }

  .k-i-sort-asc-sm:after {
    border-width: 8px 5px 0 5px;
    border-color: $color-onyx transparent transparent transparent;
  }

  .k-i-sort-desc-sm:after {
    border-width: 0 5px 8px 5px;
    border-color: transparent transparent $color-onyx transparent;
  }
}
.k-grid-aria-root {
  display: flex;
}

.k-grid-table {
  width: 100%;
  tbody {
    a {
      font-weight: bold;
    }
    td {
      color: $color-onyx;
      background-color: #fff;
      border-bottom: 15px solid #f4f4f4 !important;
      word-break: break-word;
      vertical-align: top;
      box-shadow: 0px 2px 0px 0px #e4defe;
      padding: 23px 1rem !important;
    }
  }
}

//Kendo Pager consistant UI
kendo-pager {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 30px;
  margin-top: 15px;

  kendo-pager-prev-buttons,
  kendo-pager-next-buttons,
  kendo-pager-numeric-buttons {
    .k-link {
      text-decoration: none !important;
      display: flex;
      justify-content: center;
      align-items: center;

      width: 25px;
      height: 25px;
      margin-right: 10px;

      color: #6f6767;
      background-color: #e9e9e9;

      border: 0.5px solid #ccc !important;
      border-radius: 50%;

      &:focus,
      &:hover:not(.k-state-disabled) {
        color: $color-primary !important;
        background-color: #ccc !important;
      }

      &.k-state-selected {
        color: #fff;
        background-color: $color-primary;
        border: 1px solid $color-primary !important;
        font-family: Roboto-Bold;
      }
    }
  }

  kendo-pager-numeric-buttons {
    ul.k-pager-numbers {
      list-style: none;
      display: flex;
      padding: 0;
      margin: 0;
    }
  }

  kendo-pager-prev-buttons,
  kendo-pager-next-buttons {
    display: flex;
    .k-link {
      span {
        &::after {
          color: #6f6767;
        }
      }
      &.k-state-disabled {
        cursor: auto !important;

        span {
          &::after {
            opacity: 0.5 !important;
          }
        }
      }

      .k-i-seek-w::after {
        content: "««";
      }
      .k-i-arrow-w::after {
        content: "«";
      }
      .k-i-arrow-e::after {
        content: "»";
      }
      .k-i-seek-e::after {
        content: "»»";
      }
    }
  }

  kendo-pager-info {
    position: absolute;
    right: 150px;
    top: 7px;
    display: none;
  }
  kendo-pager-page-sizes {
    display: none;
  }
}

z-payment-table,
z-transactions-table {
  kendo-grid {
    .k-grid-aria-root {
      kendo-grid-list {
        .k-grid-table {
          tbody {
            tr {
              td {
                .text-primary {
                  color: $color-primary !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
z-payment-table,
z-transactions-table,
.transactionGrid,
.paymentGrid {
  .k-grid-header-wrap table,
  .k-grid-table {
    table-layout: fixed;
  }
}

/* locked columns START */

.k-grid-content-locked {
  white-space: normal;
  border-color: inherit;
  outline: 0;
  table {
    table-layout: fixed;
  }
}

.k-grid-content-locked + .k-grid-content.k-auto-scrollable,
.k-grid-footer-locked + .k-grid-content.k-auto-scrollable,
.k-grid-header-locked + .k-grid-content.k-auto-scrollable,
.k-ie .k-grid-header-locked + .k-grid-header-wrap.k-auto-scrollable {
  display: inline-block;
}

.k-grid .k-grid-content-locked + .k-grid-content {
  box-sizing: content-box;
  display: inline-block;
}

.k-grid-content-locked,
.k-grid-footer-locked,
.k-grid-header-locked {
  display: inline-block;
  vertical-align: top;
  overflow: visible;
  position: relative;
}
/* locked columns END */

@media (max-width: 992px) {
  z-payment-table,
  z-transactions-table,
  .transactionGrid,
  .paymentGrid {
    kendo-grid {
      .col-name {
        display: block;
      }
      .k-grid-aria-root {
        .k-grid-header {
          display: none !important;
        }
        kendo-grid-list {
          margin-top: 15px;
          .k-grid-content {
            max-height: 300px;
            overflow-y: auto;
          }
          .k-grid-table {
            display: block;
            tbody {
              display: block;
              tr {
                display: block;
                width: 100%;
                margin-bottom: 15px;
                background-color: #fff;
                td {
                  display: inline-block;
                  width: 50% !important;
                  vertical-align: top !important;
                  border-bottom: 0 !important;
                  max-width: unset !important;
                  padding: 10px 1rem !important;
                  box-shadow: none;
                  .text-primary {
                    color: $color-primary !important;
                  }
                  .col-name {
                    color: $color-text-3 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.k-i-filter::before {
  content: "\F232";
}

.k-pane-wrapper .k-grid-filter-menu .k-filter-selected-items {
  margin: 1em;
  font-weight: normal;
}

.k-pane-wrapper .k-list-title,
.k-pane-wrapper .k-filter-help-text {
  padding: 8px 12px;
  display: block;
}

.k-pane-wrapper .k-column-menu .k-filter-item .k-filterable * {
  pointer-events: none;
}

.k-pane-wrapper .k-filter-menu .k-space-right {
  margin: 0.5em 10px;
  width: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.k-pane-wrapper .k-filter-menu .k-filter-tools {
  margin: 1em;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.k-rtl .k-pane-wrapper .k-filter-menu .k-space-right > .k-i-zoom,
.k-pane-wrapper[dir="rtl"] .k-filter-menu .k-space-right > .k-i-zoom,
[dir="rtl"] .k-pane-wrapper .k-filter-menu .k-space-right > .k-i-zoom {
  left: 15px;
  right: auto;
}

.k-pane-wrapper .k-filter-menu .k-space-right {
  margin: 0.5em 10px;
  width: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.k-pane-wrapper .k-filter-menu .k-filter-tools {
  margin: 1em;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.k-rtl .k-pane-wrapper .k-filter-menu .k-space-right > .k-i-zoom,
.k-pane-wrapper[dir="rtl"] .k-filter-menu .k-space-right > .k-i-zoom,
[dir="rtl"] .k-pane-wrapper .k-filter-menu .k-space-right > .k-i-zoom {
  left: 15px;
  right: auto;
}

.k-grid[dir="rtl"] .k-grid-header .k-with-icon,
.k-grid[dir="rtl"] .k-grid-header .k-filterable,
.k-rtl .k-grid .k-grid-header .k-with-icon,
.k-rtl .k-grid .k-grid-header .k-filterable {
  padding-left: calc(1.4285714286em + 14px);
  padding-right: 12px;
}

.k-grid-header .k-with-icon,
.k-grid-header .k-filterable {
  padding-right: calc(1.4285714286em + 14px);
}

.k-grid[dir="rtl"] .k-filtercell-operator,
.k-rtl .k-grid .k-filtercell-operator {
  margin-left: 0;
  margin-right: 4px;
}

.k-filter-row {
  line-height: 1.4285714286;
}

.k-filter-row td,
.k-filter-row th {
  border-width: 0 0 1px 1px;
  white-space: nowrap;
  padding: 8px 8px;
}

.k-filter-row td:first-child {
  border-left-width: 0;
}

.k-filter-row .k-multiselect,
.k-filter-row .k-dropdowntree {
  height: auto;
}

.k-filter-row .k-filtercell .k-widget.k-sized-input {
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.k-filtercell > span,
.k-filtercell .k-filtercell-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.k-filtercell > span > label,
.k-filtercell .k-filtercell-wrapper > label {
  vertical-align: middle;
}

.k-filtercell .k-filtercell-wrapper > .k-widget {
  width: 100%;
}

.k-filtercell .k-filtercell-wrapper > .k-widget {
  width: 100%;
}

.k-filtercell > span .k-button {
  visibility: visible;
  pointer-events: all;
}

.k-filtercell > span .k-button,
.k-filtercell > span .k-dropdown-operator {
  margin-left: 4px;
}

.k-filtercell .k-filtercell-operator > .k-button.k-clear-button-visible {
  visibility: visible;
  height: calc(1.4285714286em + 10px);
}

.k-filtercell .k-filtercell-operator > .k-button:not(.k-clear-button-visible) {
  visibility: hidden;
  pointer-events: none;
}

.k-filtercell .k-filtercell-operator {
  margin-left: 4px;
}

.k-filtercell .k-widget:not(.k-dropdown-operator),
.k-filtercell .k-filtercell-wrapper > .k-textbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.k-filtercell .k-filtercell-wrapper > .k-textbox {
  width: 100%;
  min-width: 0;
}

.k-filter-menu .k-filter-menu-container {
  padding: 0.5em 1em;
}

.k-filter-menu .k-widget,
.k-filter-menu .k-radio-list,
.k-filter-menu .k-textbox {
  margin: 0.5em 0;
  width: 100%;
  display: block;
}

.k-filter-menu .k-widget.k-filter-and {
  width: 6em;
  margin: 1em 0;
}

.k-filter-menu .k-switch {
  width: 6em;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.k-filter-menu .k-actions,
.k-filter-menu .k-action-buttons,
.k-filter-menu .k-columnmenu-actions {
  margin-left: -1em;
  margin-right: -1em;
  margin-bottom: -0.5em;
  padding: 0;
}

.k-filter-menu .k-actions .k-button,
.k-filter-menu .k-action-buttons .k-button,
.k-filter-menu .k-columnmenu-actions .k-button {
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
}

.k-filter-selected-items {
  font-weight: bold;
  line-height: normal;
  white-space: nowrap;
  margin: 1em 0 0.5em;
}

.k-columnmenu-item-content .k-filter-menu .k-filter-menu-container {
  padding: 1em 1em;
}

.k-grid-header,
.k-header,
.k-grid-header-wrap,
.k-grouping-header,
.k-grouping-header .k-group-indicator,
.k-grid td,
.k-grid-footer,
.k-grid-footer-wrap,
.k-grid-content-locked,
.k-grid-footer-locked,
.k-grid-header-locked,
.k-filter-row > td,
.k-filter-row > th {
  border-color: rgba(0, 0, 0, 0.08);
}

.k-spreadsheet .k-filter-wrapper {
  position: relative;
  z-index: 50;
}

.k-spreadsheet .k-filter-range {
  border-width: 1px;
  border-style: solid;
  position: absolute;
  box-sizing: border-box;
}

.k-spreadsheet .k-filter-range {
  border-color: #ff6358;
}

.k-filter ul {
  padding: 0;
}

.k-filter ul li {
  list-style-type: none;
}

.k-filter .k-filter-container,
.k-filter .k-filter-preview {
  margin-bottom: 30px;
}

.k-filter .k-filter-lines,
.k-filter .k-filter-item {
  padding-left: 8px;
}

.k-filter .k-filter-toolbar {
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  padding: 4px 0;
}

.k-filter .k-filter-toolbar::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 1px;
  top: 50%;
  left: -8px;
}

.k-filter .k-filter-toolbar .k-toolbar {
  border-style: solid;
}

.k-filter .k-filter-toolbar .k-filter-operator .k-dropdown,
.k-filter .k-filter-toolbar .k-filter-operator .k-dropdowntree {
  width: 15em;
}

.k-filter .k-filter-item {
  position: relative;
}

.k-filter .k-filter-item::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  top: -4px;
  left: 0;
}

.k-filter .k-filter-lines .k-filter-item:last-child > .k-filter-toolbar::after {
  content: "";
  position: absolute;
  width: 1px;
  top: -5px;
  bottom: 50%;
  left: -8px;
}

.k-filter .k-filter-group-main::before,
.k-filter .k-filter-group-main > .k-filter-toolbar::before,
.k-filter .k-filter-group-main > .k-filter-toolbar::after,
.k-filter .k-filter-lines .k-filter-item:last-child::before {
  display: none;
}

.k-rtl .k-filter .k-filter-lines,
.k-rtl .k-filter .k-filter-item,
.k-filter[dir="rtl"] .k-filter-lines,
.k-filter[dir="rtl"] .k-filter-item,
[dir="rtl"] .k-filter .k-filter-lines,
[dir="rtl"] .k-filter .k-filter-item {
  padding-left: 0;
  padding-right: 8px;
}

.k-rtl .k-filter .k-filter-toolbar::before,
.k-filter[dir="rtl"] .k-filter-toolbar::before,
[dir="rtl"] .k-filter .k-filter-toolbar::before {
  left: auto;
  right: -8px;
}

.k-rtl .k-filter .k-filter-item::before,
.k-filter[dir="rtl"] .k-filter-item::before,
[dir="rtl"] .k-filter .k-filter-item::before {
  left: auto;
  right: 0;
}

.k-rtl .k-filter .k-filter-lines .k-filter-item:last-child > .k-filter-toolbar::after,
.k-filter[dir="rtl"] .k-filter-lines .k-filter-item:last-child > .k-filter-toolbar::after,
[dir="rtl"] .k-filter .k-filter-lines .k-filter-item:last-child > .k-filter-toolbar::after {
  left: auto;
  right: -8px;
}

.k-filter {
  color: #656565;
}

.k-filter .k-filter-preview-field {
  color: #ff6358;
}

.k-filter .k-filter-preview-operator {
  color: rgba(101, 101, 101, 0.6);
}

.k-filter .k-filter-item::before,
.k-filter .k-filter-toolbar::before,
.k-filter .k-filter-lines .k-filter-item:last-child > .k-filter-toolbar::after {
  background-color: rgba(0, 0, 0, 0.08);
}

.k-animation-container-relative {
  position: relative;
  display: inline-block;
}

.k-animation-container-fixed > .k-popup {
  box-shadow: none;
}

.k-colorpicker + .k-animation-container .k-popup {
  padding: 0;
}

.k-animation-container-sm .k-calendar.k-calendar-range:not(.k-calendar-infinite) .k-calendar-view {
  -ms-flex-direction: column;
  flex-direction: column;
}

.k-chart-tooltip-wrapper .k-animation-container-shown,
.k-chart-tooltip-wrapper.k-animation-container-shown {
  transition: left ease-in 80ms, top ease-in 80ms;
}

.k-pdf-export .k-chart .k-animation-container,
.k-pdf-export .k-sparkline .k-animation-container,
.k-pdf-export .k-stockchart .k-animation-container {
  display: none;
}

.k-dropdown {
  margin: 0.5em 0;
  width: 100%;
  display: block;
  border-color: rgba(0, 0, 0, 0.08);
  border-width: 0;
  background-color: transparent;
  line-height: 1.42857143;
  text-align: left;
  white-space: nowrap;
  position: relative;
}

.k-dropdown .k-dropdown-wrap,
.k-dropdowntree .k-dropdown-wrap {
  background-clip: padding-box;
}

.k-list-container .k-nodata .k-button,
.k-dropdown .k-dropdown-wrap,
.k-dropdowntree .k-dropdown-wrap,
.k-button-group.k-state-disabled .k-button:not(.k-state-active),
.k-button-group .k-state-disabled:not(.k-state-active),
.k-toolbar .k-split-button .k-button,
.k-spreadsheet-filter,
.k-pivot-toolbar .k-button,
.k-fieldselector .k-list li.k-item,
.k-colorpicker .k-picker-wrap,
.k-combobox .k-select,
.k-datepicker .k-select,
.k-timepicker .k-select,
.k-datetimepicker .k-select,
.k-dateinput .k-select,
.k-editor .k-editor-toolbar-wrap a.k-tool,
.k-ct-popup .k-editor-toolbar a.k-tool,
.editorToolbarWindow.k-header.k-window-content a.k-tool,
.k-numerictextbox .k-select,
.k-switch-handle,
.k-handle,
.k-gantt-views li,
.k-scheduler-navigation .k-nav-today,
.k-scheduler-navigation .k-nav-prev,
.k-scheduler-navigation .k-nav-next,
.k-scheduler-views li,
.k-scheduler-footer li {
  color: #656565;
  background-color: #f6f6f6;
  border-color: rgba(0, 0, 0, 0.08);
  background-image: linear-gradient(#f6f6f6, #f1f1f1);
}

.k-dropdown-wrap {
  outline: 0;
  cursor: pointer;
}
.k-combobox .k-dropdown-wrap .k-ie11 {
  background-clip: border-box;
  color: #656565;
  background-color: #ededed;
  border-color: rgba(0, 0, 0, 0.15);
  background-image: linear-gradient(#ededed, #e8e8e8);
}

.k-dateinput-wrap,
.k-dropdown-wrap,
.k-picker-wrap,
.k-numeric-wrap {
  display: flex;
  flex-direction: row;
}

.k-dateinput-wrap,
.k-dropdown-wrap,
.k-picker-wrap,
.k-multiselect-wrap,
.k-numeric-wrap {
  border-radius: 2px;
  padding: 0;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  position: relative;
  transition: all 0.1s ease;
  cursor: default;
  outline: 0;
}

.k-dropdown-wrap > .k-input {
  flex: 1;
}

.k-dateinput-wrap .k-input,
.k-dropdown-wrap .k-input,
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input {
  width: 100%;
}

.k-dateinput-wrap .k-input,
.k-dropdown-wrap .k-input,
.k-picker-wrap .k-input,
.k-multiselect-wrap .k-input,
.k-numeric-wrap .k-input {
  padding: 4px 8px;
  height: calc(8px + 1.42857143em);
  box-sizing: border-box;
  border: 0;
  outline: 0;
  color: inherit;
  background: none;
  font: inherit;
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.k-input,
.k-textbox > input {
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  outline: 0;
  color: inherit;
  background: none;
  font: inherit;
  font-size: 14px;
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-appearance: none;
}

.k-filter-menu .k-widget,
.k-filter-menu .k-radio-list,
.k-filter-menu .k-textbox {
  margin: 0.5em 0;
  width: 100%;
  display: block;
}

.k-textbox,
.k-input.k-textbox,
.k-textarea {
  background-clip: padding-box;
}

.k-textbox,
.k-input.k-textbox,
.k-textarea {
  border-radius: 2px;
  padding: 4px 8px;
  width: 12.4em;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  font: inherit;
  font-size: 14px;
  line-height: 1.42857143em;
  display: inline-flex;
  vertical-align: middle;
  position: relative;
  -webkit-appearance: none;
}

.k-autocomplete,
.k-combobox .k-dropdown-wrap,
.k-datepicker .k-picker-wrap,
.k-timepicker .k-picker-wrap,
.k-datetimepicker .k-picker-wrap,
.k-dateinput .k-dateinput-wrap,
.k-textbox,
.k-input.k-textbox,
.k-textarea,
.k-multiselect-wrap,
.k-numerictextbox .k-numeric-wrap {
  color: #656565;
  background-color: #ffffff;
  border-color: rgba(0, 0, 0, 0.08);
}

.k-i-arrow-60-down::before,
.k-i-arrow-s::before,
.k-i-sarrow-s::before,
.k-i-collapse::before,
.k-i-expand-s::before {
  content: "\e006";
}

.k-icon::before {
  vertical-align: baseline;
}

.k-list > .k-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  cursor: pointer;
}

.k-reset.k-filter-menu {
  margin: 0;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
  outline: 0;
  text-decoration: none;
  font: inherit;
  list-style: none;
}

.k-action-buttons {
  border-width: 1px 0 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  clear: both;
  margin-bottom: -0.5rem;
  padding: 0;
}

.k-button-group {
  background-clip: padding-box;
}

.k-button-group {
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
  flex-direction: row;
  vertical-align: middle;
  position: relative;
}

.k-filter-menu .k-action-buttons .k-button {
  margin: 0;
  width: auto;
}

.k-button-group .k-group-start,
.k-button-group .k-button:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.k-button.k-primary {
  background-color: $color-header-blue;
  color: $color-white;
  &:not([disabled]):hover {
    background-color: #4080bf;
  }
  &:disabled {
    opacity: 0.65;
    cursor: default !important;
  }
}
.k-button {
  color: $color-header-blue;
  background-color: $color-white;
  &:hover {
    background-color: $color-header-blue;
    color: $color-white;
  }
}

.k-filter-menu > div > div > .k-button {
  margin: 0.5em 1% 0;
  width: 48%;
  box-sizing: border-box;
  display: inline-block;
}

.k-action-buttons .k-button {
  border-radius: 0;
  padding: 12px 16px;
  border-width: 0;
  border-color: inherit;
  flex: 1 1 auto;
}

.k-button {
  border-radius: 2px;
  padding: 1rem;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  font-size: 14px;
  line-height: 1.42857143;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  position: relative;
}

.k-list-scroller {
  position: relative;
  overflow: auto;
}

.k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-selected {
  background-color: #ededed;
}

.k-list .k-item.k-state-focused,
.k-list .k-item:focus,
.k-list-optionlabel.k-state-focused,
.k-list-optionlabel:focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.13);
}

.k-list .k-item,
.k-list-optionlabel {
  padding: 4px 8px;
  min-height: 1.42857143em;
  line-height: 1.42857143em;
  white-space: normal;
  transition-property: color, background-color, outline-color, box-shadow;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  &:hover {
    background-color: #f8f9fa;
  }
}

.mdi.mdi-menu-down.k-i-arrow-s.k-icon {
  font-size: 22px;
}

.k-grid-filter {
  float: right;
  margin-right: 1rem;
}

.k-animation-container {
  border-radius: 0 0 0.2rem 0.2rem;
  position: absolute;
  overflow: hidden;
  z-index: 100;
}

.k-popup {
  border-color: rgba(0, 0, 0, 0.08);
  color: #656565;
  background-color: #f9f9f9;
  background-clip: padding-box;
  margin: 0;
  padding: 0px 0px;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.4285714286;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.k-animation-container-shown {
  overflow: visible;
}

.k-animation-container-fixed {
  position: fixed !important;
}

.k-animation-container-shown,
.k-animation-container > .k-popup {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03), 0 4px 5px 0 rgba(0, 0, 0, 0.04);
}

.k-animation-container-fixed > .k-popup {
  box-shadow: none;
}

.k-popup.k-popup-transparent {
  border-width: 0;
  background-color: transparent;
}

.k-popup > .k-widget {
  border-width: 0;
}

kendo-combobox {
  .k-dropdown-wrap.k-state-default,
  .k-dropdown-wrap.k-state-default .k-searchbar {
    width: 100%;
    border-radius: 5px 5px 5px 5px;
  }
  .k-dropdown-wrap .k-input {
    border-radius: 5px 5px 5px 5px;
    @extend .basic-control;
    cursor: pointer;
    height: 2.385rem;
    &.read-only {
      color: $color-black;
      cursor: text;
    }
    padding: 0.375rem 0.75rem;
    width: 100%;
    &:focus {
      box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1) !important;
      border: 1px solid #ced4da;
      outline: none;
    }
  }

  .k-icon::before {
    display: none;
  }

  &.ng-touched.ng-invalid {
    .k-input {
      border-color: $color-zing-red;
    }
  }

  .k-i-arrow-s {
    @extend .basic-control;
    cursor: pointer;
    background: $color-white
      url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='50' fill='%238A8A8A'><polygon points='0,0 100,0 50,50'/></svg>")
      no-repeat right 0.75rem top 19px/16px 8px !important;
    &.read-only {
      color: $color-black;
      cursor: text;
    }
    width: 30px;
    position: absolute;
    display: inline-block;
    border: none;
    border-radius: 0;
    box-shadow: none;
    margin-left: -32px;
    margin-top: 2px;
    height: 2rem;
    background-position: center !important;
  }
}

.k-animation-container-shown {
  transition: left ease-in 80ms, top ease-in 80ms;
}

.packaging-detail-wrap {
  .k-animation-container.k-animation-container-shown {
    box-shadow: unset !important;
    .tariffCodePopup {
      background-color: $color-white;
      margin-right: 30px;
      .k-nodata {
        padding-left: 0.5rem;
      }
    }
  }
}

.k-list.k-reset {
  padding-inline-start: 0px;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}

.k-item.k-state-focused {
  box-shadow: unset !important;
  border: unset !important;
}

.shipment-summary .k-header .k-link span::before,
.shipment-reconciliation .k-header .k-link span::before,
.paid-statements .k-header .k-link span::before,
.statement-details .k-header .k-link span::before,
.transactions-listing .k-header .k-link span::before {
  content: "\F045" !important;
}

.shipment-summary .k-header .k-link span::after,
.shipment-reconciliation .k-header .k-link span::after,
.paid-statements .k-header .k-link span::after,
.statement-details .k-header .k-link span::after,
.transactions-listing .k-header .k-link span::after {
  content: "\F05D" !important;
}


.memo-items-grid{
  table {
    th, td {
      font-size: 1rem; 
      text-align: left;
      font-family: 'Roboto-Regular';
    }
    th {
      color: $color-text-1;
      letter-spacing: 0;
    }
    td {
      .item-id {
        color: $color-header-blue;
        line-height: 19px;
      }
      .quantity, .description, .total-price {
        color: $color-text-1;
        line-height: 19px;
      }
      .total-price {
        text-align: right;
        font-family: 'Roboto-Bold';
      }
    }
  }
}

@media (max-width: 991px) {

.multi-line span, .multi-line{
  white-space: pre-wrap !important;
}
}
.memo-details-grid {
  .buy-column.ng-star-inserted {
    text-align: center;
    background-color: $color-base !important;
    background-clip: content-box !important;
  }
}