@import 'src/styles/_scss/utils/_variables.scss';

z-buyer-memo-details,
z-buyer-memo-grid {
  // Alerts
  .alert-danger {
    border-color: $color-zing-red !important;
  }
}

.memo-details .table td {
  background-clip: padding-box
}

// Memo-Dashboards
.memo-dashboard {
  max-width: 1366px;

  .memo-dashboard-wrapper {
    border-radius: 1rem;
  }

  .memo-title-text,
  .grid-title-text {
    color: $color-dark-blue-link;
  }

  .memo-title-text {
    font-family: RobotoCondensed-Light;
    line-height: 50px;
    font-size: 54px;
  }

  .grid-title-text {
    font-family: RobotoCondensed-Bold;
    font-size: 26px;
  }

  .memo-actions {
    align-self: end;
    label {
      color: $color-header-blue;
      font-weight: bold;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .no-sort-indicator {
    overflow: hidden;
  }

  .track-num-underline {
    cursor: pointer !important;
    color: $color-zing-bar !important;
    border-bottom: 1px solid $color-zing-bar !important;
  } 
}

@media(max-width:991px) {
  .memo-dashboard {
    flex-direction: column;

    .logo-title-wrapper {
      flex-direction: column;      
    }

    .actions-data-wrapper {
      flex-direction: column;      
    }

    .memo-title {
      padding-bottom: 0 !important;
    }

    .memo-logo {
      padding-top: 2rem !important;
      padding-bottom: 1rem !important;
    }

    .memo-actions {
      padding-left: 0.9rem !important;
      padding-bottom: 2rem;
    }
  }
}

// Memo Details
.memo-details {
  max-width: 1366px;

  .description-line-height {
    line-height: initial;
  }

  .memo-title {
    font-family: 'RobotoCondensed-Light';
    line-height: 50px;
    font-size: 54px;
    color: $color-title-dark-blue !important;
  }

  .dark-blue-text {
    color: $color-title-dark-blue !important;
    font-weight: 700;
  }

  .green-text {
    color: $color-green-success-message !important;
    font-weight: 700;
  }

  .memo-actions{
    label {
      color: $color-header-blue;
      font-family: 'Roboto-Bold';
      font-size: 14px;
      letter-spacing: 0;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .card-container {
    .seller-location .seller-location-list{
      max-width: 425px;

      &.ng-select-disabled .ng-select-container {
        cursor: default;
      }
    }
    .address {
      .memo-link {
        padding: 0.8rem;
        .memo-icon {
            color: $color-header-blue;
            font-size: 20px;
        }
      }
      .address-title {
        color: $color-title-dark-blue;
        font-size: 1.5rem;
      }

      .address-details {
        div {
          line-height: 1.4rem;
          font-size: 1rem;
          color: $color-icon-grey;
        }
      }
    }
    .memo-tb {
      color: $color-icon-grey;
      .memo-detail-textbox{
        width: 3.5rem !important;
      }
      .roboto-regular {
        font-family: 'Roboto-Regular';
      }
    }

    .description:first-letter {
      text-transform: capitalize;
  }

    .add-items-container {
      .add-item-title {
        font-size: 0.75rem;
      }
      
      .show>.btn-outline-info.dropdown-toggle {
        color: $color-white;
        background-color: $color-zing-btn-outline;
        border-color: $color-zing-btn-outline;
      }
    }
    
    .total-price {
      justify-content: flex-end;
      color: $color-text-1;
      font-family: 'Roboto-Regular';
      font-size: 1rem;
      line-height: 19px;
      margin-right: 1rem;
    }

    .disclaimer {
      opacity: 1;
      padding-top: 1rem;
      padding-left: 0px;
      font-family: 'Roboto-Regular';
      font-size: 0.85rem;
      color: $color-disclaimer-black;
      letter-spacing: 0;
      line-height: 18px;
    }
    .button-container {
      justify-content: flex-end;
      .btn {
          font-weight: 500;
          min-width: 7.5rem;
      }
      .btn-outline-info {
          font-weight: 900;
          border-color: $color-white;
          background-color: $color-white;
          color: $color-dark-blue;
          &:hover {
          font-weight: 900;
          }
      }
    }
  }

  .table-responsive {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.14);
    border-radius: 0.5rem;
  }

  .buyer-memo-details {
    tr.comment-row {
      &:first-child {
        td {
          border: none !important;
        }
      }
    }
  }

  .table-sticky-border {
    border-collapse: separate !important;
    border-spacing: 0;
  }

  .sticky-border {
    box-shadow: -10px 0px 15px 0px rgb(0 0 0 / 15%);
    clip-path: inset(0px -15px 0px -15px);
  }

  .stickyEnd {
    position:sticky;
    right:0;
  }

  .min-width-item-description {
    min-width: 100px;
  }

  .horizontal-divider {
    height: 1px;
    width: 100%;
    border: 1px solid $color-base-3;
  }

  .table-memo-items {
    tbody tr {
      height: 80px;
    }
  }

  .memo-id-min-width {
    min-width: 75px !important;
  }

  td {
    .item-id {
      color: $color-header-blue;
      line-height: 19px;
      text-decoration: underline !important;
    }
  }
}

@media (max-width: 700px) {
  .memo-details {
    .min-width-item-description {
      min-width: 0px;
    }

    .memo-title {
      font-size: 34px;
    }

    .card-container {
      .address {
        .address-title {
          color: $color-title-dark-blue;
          font-size: 16px;
        }

        .address-details {
          div {
            line-height: 1.4rem;
            font-size: 14px;
            color: $color-icon-grey;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .m-block {
      display: block !important;
  }
  .add-items-container {
      flex-direction: column-reverse;
  }
  .button-container {
      .cancel-btn,
      .complete-memo-btn {
      margin-top: 1rem;
      }
  }
}

.item-accepted {
  color: $color-green-success-message;
}
