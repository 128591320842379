// bootstrap v5.0.0 -> 5.2.0 breaking changes
.row>*:not([class*='col-'], [class^='col-']) {
    flex-shrink: unset;
    width: unset;
    max-width: unset;
    padding-right: unset;
    padding-left: unset;
    margin-top: unset;
}
.row .col {
    padding-right: 15px;
    padding-left: 15px;
}