@import 'src/styles/_scss/utils/_variables.scss';
.payment {
    .credit-card {  
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(103,103,103,0.25);
        height: 10em;
        color: $color-white;
        padding: 1em;
        float: right;
        width: 16em;
        img {
            height: 2em;
        }

        .circle {
            border: 1px solid $color-white;
            border-radius: 50%;
            width: 5px;
            height: 5px;
            margin-bottom: 1px;
        }
        .dot{
            font-size: 21px;
        }
        .number{
            font-size: 14px;
            line-height: 25px;
        }
        .heading{
            padding-bottom: 0.4em;
            font-family: Roboto-Light;
            font-size: 10px;
            opacity: 0.75;
        }
        .details{
            font-family: Roboto-Light;
        }
        .disabled {
            opacity: .2;
        }
    }
    .inactive {
        background: $color-onyx !important;
        opacity: 0.2;
    }
    .other {
        color: $color-onyx;
        box-shadow: 0 2px 8px 0 rgba(103,103,103,0.25);
        float: left;

        .circle{
            border: 1px solid $color-onyx;
        }
        .title {
            font-family: Roboto-Light;
            .heading {
                font-family: Roboto-Medium;
            }
        }
    }
    .card-lists {
		overflow-x: auto;
        display: flex;
        height: 12em;
        cursor: context-menu;
		.card {
            min-width: 16em;
            margin-right: 1em;
        }
        &:after {
            top: 0;
            content: "";
            height: 90%;
            pointer-events: none;
            position: absolute;
            width: 20%;
            right: 0;
            background: linear-gradient(to right, rgba(255,255,255, 0) 0%, rgba(255,255,255, 1) 100% );
        }
    }

    .card-lists::-webkit-scrollbar {
		display: hidden;
	}
}



//Call to set background color for payment cards
@include payment-option(1, $color-ruby, $color-citrine);
@include payment-option(2, $color-primary, $color-turquoise);


@media (max-width: 992px) {
    .payment {
        .credit-card {
            margin: 1em 0 1em 0;
            float: left;
        }
    }
}

.payment-methods {
    .methods {
        width: 100%;
    }
    .info-type {
        ul{
            display: flex;
            list-style: none;
            padding-left: 1em;
        }
        li {
            margin-right: 5em; 
            text-transform: uppercase;
            color: $color-text-3; 
        }
    }
    .underLine {
        border: 2px solid $color-text-3;
        position: relative;
        box-sizing: border-box;
        height: 3px;
        width: 90%;
    }
    .underLine:before {
        content:'';
        background:$color-primary;
        width:27%;
        height:3px;
        margin: -2px;
        position:absolute; 
    }
}