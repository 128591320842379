//High Contrast Changes
body.contrast-theme {
  .top-bar,
  .btn-primary-purple,
  .btn-secondary-white,
  .btn-tertiary-blue,
  .btn-quick-quote,
  .tile-style-2, 
  .tile-selector label input:checked + span,
  .tile-checkbox input:checked + .tile-style-1,
  .shipment-successful .form-container-header,
  .input-bubble,
  .icon-box,
  .container .claim-successful .container-header {
    background: $color-primary!important;
  }

  .tile-style-2, .input-bubble {
    border-right-color: $color-primary!important;
  }

  .suggested-presets-container ul li {
      &:hover,
      &:focus { 
        background: $color-primary!important;
      }
  }

  .policy-details {
    .details {
      .label {
        color: #616161 !important
      }
    }
  }

  #invoiceGrid {
    td:last-child {
             .view-wrap {
                background: $color-primary!important;
             }
          }
    }
   
}
