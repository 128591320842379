// Global variables

//Success Messages
$color-green-success-message: #4E792E;

// BASIC COLORS
$color-white: #FFF;
$color-black: #000;
$color-red: #FF0000;

//BG COLORS
$color-base: #F4F4F4;
$color-base-2: #FAFAFA;
$color-base-3: #F5F5FF;
$color-base-4: #cccccc;

// PLATFORM THEME COLORS
$color-primary: #615E9B;
$color-secondary: #525252;
$color-tertiary: #DDD;
$color-quaternary: #93B3F7;
$color-webkit-primary:  #395175;

// PLATFORM GEM COLOR PALETTE
$color-sapphire: #004B98;
$color-turquoise: #33CCCC;
$color-emerald: #008563;
$color-ruby: #C01F5D;
$color-citrine: #FBAB18;
$color-onyx: #4A4A4A;
$color-dashboard-blue: #3370ac;
$color-header-blue: #6699CC;
$color-appraisals-blue: #3B8CB2;
$color-appraisals-light-blue: #CAE9FC;
$color-appraisals-blue-text: #446E9B;
$color-appraisals-table: #D5EBEF;
$color-appraisals-email-preview-standout-text: #1c96d4;
$color-appraisals-email-preview-blue: #0059a6;
$color-icon-blue: #47638f;
$color-icon-grey: #666666;
$color-icon-light-grey: #DDDDDD;
$color-green-success: #3f7b1e;
$color-strong-yellow-green: #6dd400;
$color-summer-sky-blue: #34b8e0;
$color-table-widget-light-blue: #EAF2F9;
$color-disclaimer-black: #151515;
$color-tax-banner-link: #222222;
$color-tax-cta-down: #7B6225;
$color-tax-cta-hover: #FEF7E6;

// TEXT COLORS
$color-text-1: #333333;
$color-text-2: #434143;
$color-text-3: #757575;
$color-text-4: 	#C1C1C1;
$color-text-5: #6e757c;
$color-text-6: #508baf;
$color-text-7: #3b3b3b;
$color-text-8: #676767;
$color-text-9: #6c757d;
$color-text-10: #555555;
$color-text-11: #989898;
$color-text-12: #6a6a6a;
$color-placeholder: #777777;
$color-zing-blue: #0058a6;
$color-zing-grey: #959595;
$color-disclaimer: #990407;
$color-text-grey: #686868;

// MISCELLANEOUS
$color-blue: #1E1749;
$color-box-shadow: #E9E7E7;
$color-separation-line-border: #979797;
$color-quote-error: #A21818;
$admin-fee-message: #555;
$draft-delete-button: #AD3537;
$draft-cancel-button: #55627E;
$logo-delete-background: #AD3537;
$tooltip-gray: #343a40;
$color-dark-blue-link: #2D4F75;
$color-zing-link: #69c;
$color-yellow-info-alert: #e3a556;
$color-badge-background: #F9C851;
$appraisals-dashboard-header: #d9e9f8;
$light-pink: #ffb6c1;
$color-clear-search-icon-hover: #D0021B;
$color-dark-blue: #46638f;
$appraisal-widget-icon-background: #7283A1;
$watch-brand-tile-background: #F7F7F7;
$multiselect-light-blue-hover: #D1E8FF;
$appraisal-grid-boarder: #E5E5E5;
$color-alert-success-close-hover: #086637;
$color-alert-danger-close-hover: #500000;
$color-scrollbar: #DDDADA;
$color-scrollbar-track: #F9FCFC;
$color-icon-dark: #222222;
$color-ecom-banner-text: #2d4a76;
// TABLE ROW COLOR
$color-table-odd-row: #F7F6FA;
$color-table-even-row: #FCFCFD;
$color-table-border: #f2f2f2;
$color-table-expanded-border: #797979;

// BUTTON COLORS
$color-btn-border:  #80bdff;
$color-checkbox-border: #dee2e6;
$color-btn-disabled: #AAAAAA;

//TOOLTIP COLORS
$color-border-1: #D2E6FB;
$color-background-1: #F7FAFF;
$color-background-2: #F8FBFF;
$color-background-3: #e9ecef;
$color-background-4: #5b7288;
$color-background-5: #f7f7f7;

//CONSOLIDATED DASHBOARD COLORS
$color-zing-brown: #666666;
$color-zing-border: #929292;
$color-zing-hover: #E1EBF5;
$color-zing-bar: #6699CC;
$color-zing-border-right: #AAAAAA;
$color-zing-error-text: #FF0000;
$color-zing-text-toggle: #00456f;
$color-zing-track-input: #7F7F7F;
$yellow:     #f9bc0d;
$color-zing-green-arrow: #70B603;
$color-zing-red-arrow: #990000;
$color-zing-light-grey: #E4E4E4;

// THESE VARIABLES ARE FROM THE PLATFORM WEBKIT
// Topbar Height
$topbar-height:                     132px;
$topbar-search-width:               340px;
$bg-topnav:                       #37404a;
$form-height:                       34px;
$hamburger-menu-base: #ccc;
$hamburger-menu-text: #2b2b2b;
$shadow-black: rgba(49, 57, 66, 0.5);
$shadow:                            0 0 35px 0 rgba(49, 57, 66, 0.5);
$shadow-lg:                         0 0 45px 0 rgba(0, 0, 0, 0.12);
$dropdown-lg-width:                 330px;
$warning:       $yellow;
$notification-blue: #46638f;
$text-muted: #8c8c8c;
$color-link: #169BD5;
$color-slider-dark-blue: #46638f;
$color-no-results: #8C1A11;
$color-popover-text: #212529;
$color-title-dark-blue: #28395B;
$color-zing-red: #900900;
$color-zing-tooltip-background: #484D53;
$color-gray: #6B6B6B;
$color-gray-1: #d2d2d2;
$color-text-secondary: #293b5e;
$color-success-green: #10c469;
$color-gray-400: #ced4da;
$color-steel-blue: #4D80B3;
$color-light-blue: #0BB0F7;
$color-thumbnail-background: #DAF3FE;

//Zing btn color
$color-zing-btn-blue: #46638f;
$color-zing-btn-modal-blue: #579AD1;
$color-zing-btn-dark-blue: #354b6d;
$color-zing-btn-outline: #69c;
$color-zing-video-title:rgba(102, 153, 204, 0.996078431372549);
$color-zing-btn-inputgroup-outline: #b3bcce;

// LANDING PAGE
$color-header-button: #2C5075;
$color-dark-gray: #10151c;
$color-dark-gray2: #2C2C2C;
$color-light-gray: #999999;
$color-light-gray2: #bcbcbc;
$color-header-text: #006699;

$color-merge-accounts-green: #40d188;

$color-light-grey-search: #B3B2B2;

$color-help-video-background: #124f86;
$color-help-video-title-link: #ccffff;
$color-help-video-icon-border: #66ccff;
$color-help-video-bottom-bar: #6699cc;
$color-yellow-import-background: #fef9ed;
$color-yellow-import-border: #F9C851;

// Compare diamonds modal
$color-text-green: #4B9002;
$color-background-alternate-row: #fbfdff;
$color-grid-lines: #eeeeee;

// Shipment dashboard date selection
$color-custom-day: #7b7b7b;
$color-custom-day-back: #edeff1;
$color-custom-day-disabled: rgba(0, 0, 0, .38);
$color-arrow-right: rgba(0, 0, 0, 0.54);
$color-navigation-chevron: #adb5bd;

// Shipment type legend
$color-legend-blue: #137c9f;
$color-legend-orange: #ff8315;
$color-legend-yellow: #ffdb15;

// Shipment dashboard table
$color-table-shadow:  rgba(0,0,0,0.15);
$color-scrollbar-border:  rgba(0,0,0,0);

// AGS partner colors
$color-ags-dark: #3D4F3F;
$color-ags-light: #467452;
$color-ags-info: #E3EDE4;
$color-ags-info-border: #749A79;
$color-ags-dark-1: #2b3e2e;
$color-ags-light-green:#517355;
// Gem & Jewel partner colors
$color-gnj-orange: #f7590a;

// Banner colors
$color-yellow-warning-message: #81682a;
$color-red-danger-message: #900;
$color-blue-info-message: #35506a;
$color-light-blue-info-message: #e0ebf5;
$color-light-yellow-warning-message: #fef4dc;

// new banner colors
$color-warning-background: #F9C851;
$color-warning-accent: #555555;
$color-green-success: #4E792E;
$color-red-error: #990000;
$color-info-blue: #2D4A76;
$color-info-background: #E0EBF5;
$color-banner-green: #ECF5E5;
$color-banner-yellow: #FCF8E3;

$color-price-range-text: #222222;
$color-footer-grey: #eaeaea;

$color-border-grey: #DEE2E6;
//Memo
$color-control-grey:#ededed;
$box-shadow-light: -10px -20px 30px -20px rgb(0 0 0 / 20%) inset;

// Dashboard redesign - Widgets
$widget-blue-color: #6699CC;
$widget-text-line-height: 20px;
$widget-text-font-size: 14px;

//Lab Grown Diamonds
$color-feedback-info: #2D4A76;
$color-returnable-pill: #ECF5E5;

//JMCC Dashboard
$jmcc-dashboard-failed-status: #FFE1E1;
$jmcc-dashboard-failed-status-border: #FFC7C8;
$step-bckg-color: #466CA6;
$step-text-color: #fff;
$breadcrumb-link: #688bc1;
$breadcrumb-span: #6c757d;
$page-info: #0000006b;
$jmcc-dashboard-ligth-blue: #F5FAFF;
$selected-background: #f0f0f0;

//Modals font family
$font-family-roboto: "Roboto";
$font-family-roboto-condensed: "Roboto Condensed"
