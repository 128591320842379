@import "src/styles/_scss/utils/_variables";

@mixin k-grid-font($font-weight, $line-height: 1.5, $color: $color-zing-brown) {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: $font-weight;
  line-height: $line-height;
  color: $color !important;
}

kendo-grid.numeric-pager {
  .k-numeric-wrap {
    width: 40px;
  }

  .k-widget .k-numerictextbox {
    display: inline-block !important;
  }

  div.page-navigation.ng-star-inserted,
    div.page-nav-bar.ng-star-inserted {
      kendo-pager-input {
        margin-right: 5px;
        margin-left: 5px;
      }

      kendo-pager-next-buttons,
      kendo-pager-prev-buttons {
        a.k-link.k-pager-nav{
          display: flex !important;
          margin-right: 0;
        }
        .k-link {
          span {
            &::after {
              font-weight: bold;
            }
          }
        }
        .k-state-disabled {
          span {
            &::after {
              font-weight: normal !important;
            }
          }
        }
      }
    }

    kendo-pager {
      flex-direction: row;
      display: flex;
      justify-content: center;
      position: sticky;
      bottom: 0;
      z-index: 99;
      background: $color-white;
      height: 70px !important;
      border-top: 1px solid $color-table-border !important;
    }

    kendo-pager-page-sizes {
      display: flex;
      align-items: center;
      select {
        border: 1px solid $color-checkbox-border;
        border-radius: 4px;
        width: 80px;
        height: 32px;
        color: $color-text-10;
        background-color: $color-white;
        padding-left: 10px;
        margin-left: 10px !important;
        margin-right: 5px !important;
      }
    }

    @media (max-width: 519px) {
      kendo-pager {
        flex-direction: column;
        height: 100px !important;
        .page-navigation {
          padding-top: 10px;
        }
      }
    }
}

kendo-pager {
  
  margin-top: 15px;
  height: auto !important;

  .page-info {
    @include k-grid-font(600);
    margin-right: auto;
  }

  .k-pager-last {
    margin-right: 0px;
  }

  kendo-pager-prev-buttons,
  kendo-pager-next-buttons,
  kendo-pager-numeric-buttons {
    .k-link {
      @include k-grid-font(400, 1.25, $hamburger-menu-text);

      width: 35px;
      height: 35px;
      margin-right: 10px;

      background-color: transparent !important;
      border-radius: 30px !important;
      border: none !important;

      &.k-state-selected {
        color: $color-white !important;
        background-color: $notification-blue !important;
        border-color: $notification-blue !important;

        &:focus {
          box-shadow: 0 0 0 0.1rem rgba(70, 99, 143, 0.25) !important;
        }
      }

      &:focus:not(.k-state-disabled) {
        box-shadow: 0 0 0 0.1rem rgba(70, 99, 143, 0.25) !important;
      }

      &:hover:not(.k-state-selected):not(.k-state-disabled) {
        color: $hamburger-menu-text !important;
        background-color: $color-background-3 !important;
      }
    }
  }

  kendo-pager-prev-buttons,
  kendo-pager-next-buttons {
    .k-link {
      font-size: 13px;

      span {
        color: $hamburger-menu-text !important;

        &::after {
          color: inherit;
          display: inline-block;
          font: normal normal normal 24px/1 "Material Design Icons";
          font-size: 14px;
          text-rendering: auto;
          line-height: inherit;
          -webkit-font-smoothing: antialiased;
        }
      }

      &.k-pager-nav {
        display: none !important;
      }

      &.k-pager-last {
        margin-right: 0px;
        display: flex !important;
      }
      &.k-pager-first {
        display: flex !important;
      }
      &.k-state-disabled {
        cursor: auto !important;
        span::after {
          color: $color-text-9 !important;
          opacity: 1 !important;
        }
      }

      .k-i-seek-w::after {
        content: "\F13D";
        font-weight: bold !important;
      }
      .k-i-arrow-w::after {
        content: "\F141";
      }
      .k-i-arrow-e::after {
        content: "\F142";
      }
      .k-i-seek-e::after {
        content: "\F13E";
        font-weight: bold !important;
      }
    }
  }
}

@media (max-width: 992px) {
  kendo-pager {
    flex-direction: column;

    .page-info {
      margin-right: 0 !important;
      margin-bottom: 2em !important;
    }

    .page-links {
      margin-bottom: 1em;
    }
  }
}

@media only screen and (max-width: 600px) {
  kendo-pager-prev-buttons,
  kendo-pager-next-buttons,
  kendo-pager-numeric-buttons {
    .k-link {
      font-size: 11px !important;
      width: 20px !important;
      height: 20px !important;
      margin-right: 8px !important;
    }
  }
}

kendo-grid.paid-statements-grid kendo-pager,
kendo-grid.reconciliation-grid kendo-pager,
kendo-grid.transactions-listing-grid kendo-pager {
  position: unset;
}
