// Style file containing generic styles of all the type of form inputs
@import '../utils/variables';
//Chrome browser outline fix
.form-control,
.form-control-lg,
.form-control-sm,
.text-select,
a,
div {
    outline: none !important;
}

// .form-control:focus {
//     box-shadow: none !important;
// }

.idexSearchComponentTitle {
    min-height: fit-content;
}

.zing-ui textarea,
textarea {
    &.non-resizable {
        resize: none;
    }
}

//basic layout of controls- can be extended at several places
.basic-control {
    background-color: $color-white;
    border: 1px solid $color-tertiary;
    border-radius: 5px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    color: $color-onyx;
    font-size: 14px;
    height: 55px;
    line-height: 16px;
    &:focus {
        box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);
    }
    &:disabled {
        background-color: $color-base;
        color: $color-text-4;
    }
    &[readonly] {
        border: none;
        background-color: transparent;
        box-shadow: none;
        height: 20px;
        padding: 0;
        color: $color-black;
    }
}

// main form container carrying label, buttons, info-error messages and icons.
.form-group {
    margin-bottom: 1.5rem;
    text-align: left; //label and sub label (genrally on the right most top corner of inout control )
    label {
        color: rgba(74, 74, 74, 0.8);
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        margin-bottom: 12px;
        .sub-label {
            color: $color-primary;
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
            position: absolute;
            right: 0;
            top: 1px;
        }
    } // input text field
    .form-control {
        @extend .basic-control;
    } // for field level error validations
    small {
        color: $color-ruby;
        font-size: 12px;
        line-height: 14px;
        margin-top: 1em;
        display: block;
        &.warning-message {
            color: darkgoldenrod;
        }
    } // for field level default text
    small.default {
        color: $color-text-3;
        .weak {
            color: $color-ruby;
        }
        .strong {
            color: $color-emerald;
        }
    } // for field level default info
    small.default-info {
        color: $color-primary;
    } //class for dropdowns
    .form-select {
        @extend .basic-control;
        cursor: pointer;
        background: $color-white url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23615E9B' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center;
        background-size: 8px 10px;
        &.read-only {
            color: $color-black;
            cursor: text;
        }
        &.form-control {
            padding-right: 2.25rem !important;
        }
    } // grouped controls
    .input-group.prepend {
        .input-group-prepend {
            // dropdown grouped with another control
            .form-select {
                border-radius: 5px 0 0 5px;
            }
        }
        .form-control {
            @extend .basic-control;
            border-radius: 0 5px 5px 0;
        }
    } // grouped controls
    .input-group.append {
        .input-group-append {
            // dropdown grouped with another control
            button {
                border-radius: 0 5px 5px 0;
            }
        }
        .form-control {
            @extend .basic-control;
            border-radius: 5px 0 0 5px;
        }
    }
}

// style class for text dropdowns
.text-select {
    height: 30px;
    background-color: transparent;
    min-width: fit-content;
    border: none;
    cursor: pointer;
    &:focus {
        box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);
    }
}
.form-select {
    &:focus {
        box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1)!important;
        border: 1px solid #ced4da;
    }
}
.custom-control-input:focus~.custom-control-label:not(.zing-ui)::before {
    box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1)!important;
    border: 1px solid #ced4da;
}

//customize style for bootstrap 4 radio and checkbox input controls
.custom-control-label:not(.zing-ui) {
    padding-left: 1em;
    color: $color-onyx;
    cursor: pointer;
    &::before {
        top: 0;
        width: 1.4rem;
        height: 1.4rem;
        background-color: $color-tertiary;
    }
}

.custom-radio-dark .custom-control-label:not(.zing-ui) {
    &::before {
        border: 1px solid $color-base-4;
    }
}

.custom-radio:not(.zing-ui) .custom-control-input:checked~.custom-control-label:not(.zing-ui)::before {
    background-color: $color-tertiary;
    box-shadow: none;
}

.custom-radio:not(.zing-ui) .custom-control-input:checked~.custom-control-label:not(.zing-ui)::after {
    background-color: $color-primary;
    background-image: none;
    width: 0.8rem;
    height: 0.8rem;
    left: -1.18rem;
    top: 0.29rem;
    border-radius: 50%;
}
.custom-radio:not(.zing-ui) {
    .custom-control-input {
        z-index: 1;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
    .custom-control-label:not(.memo-legal-label, .p-0) {
        padding-left: 10px!important;
        line-height: 20px;
    }
}
.custom-checkbox {
    width: fit-content;
    .custom-control-label:not(.zing-ui) {
        padding-left: 10px!important;
        line-height: 23px;
        height: fit-content;
        &::before {
            height: 20px;
            width: 20px;
        }
    }
    input[type=checkbox] {
        // display: none;
        left: 0;
        z-index: 1;
        margin: 0;
        opacity: 0;
        // width: 24px;
        // height: 25px;
        // margin-left: -0.25rem;
        width: 100%;
        height: 100%;
        margin-left: 0;
        margin-top: 0;
        cursor: pointer;
    }
}

.custom-checkbox .custom-control-input:checked~.custom-control-label:not(.zing-ui)::before {
    background-color: $color-primary;
    outline: none;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label:not(.zing-ui)::after {
    height: 35px;
    top: -7px;
    width: 25px;
    left: -26px;
} //customized style for input group controls: required for OTP
.input-group.otp-control {
    padding: 0 3em;
    margin: 3em 0;
    .form-control {
        background-color: $color-base;
        border: 1px solid $color-tertiary;
        border-radius: 5px;
        height: 4em;
        margin: 0 5px;
        text-align: center;
    }
}

@media (max-width: 991.98px) {
    .input-group.otp-control {
        padding: 0;
        margin: 1em 0;
        .form-control {
            height: 3em;
        }
    }
    .custom-control-label:not(.zing-ui) {
        font-size: 15px;
    }
} //customized style for email verification input control
.form-group.email-veification {
    .form-control {
        border: none;
        box-shadow: none;
        border-bottom: 1px solid;
        border-radius: 0;
        font-family: Roboto-Medium;
        font-size: 20px;
        height: 32px;
        letter-spacing: 0;
        text-align: center;
    }
    small {
        color: $color-text-2;
        font-size: 12px;
        line-height: 14px;
        font-style: normal;
    }
}

// Generic class for styling password input field's show password icon
.password {
    position: relative;
    .show-error {
        position: absolute;
        top: 3.2em;
        right: 4.5em;
    }
    .show-password {
        position: absolute;
        top: 3.3em;
        right: 2.2em;
    }
}

@media (max-width: 991.98px) {
    .form-group.email-veification {
        .form-control {
            font-size: 16px;
        }
    }
    .password {
        .show-error {
            right: 3em;
            ;
        }
        .show-password {
            right: 1.2em;
        }
    }
}

.password-validation {
    .check {
        color: $color-onyx;
        font-size: 14px;
        letter-spacing: 0.64px;
        line-height: 17px;
        margin-bottom: 7px;
        font-family: Roboto-Light;
        &:before {
            border: 1px solid $color-text-4;
            border-radius: 50%;
            display: inline-block;
            font-size: 10px;
            width: 5px;
            height: 9px;
            height: 2em !important;
            margin: 0 10px 0px 0;
            width: 2em !important;
            content: " ";
            vertical-align: middle;
        }
        &:after {
            content: " ";
            display: block;
            width: 5px;
            height: 9px;
            border: solid $color-text-4;
            border-width: 0 2px 2px 0;
            position: absolute;
            top: 5px;
            left: 8px;
            -webkit-transform: rotate(45deg); // tilts the shape
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
    .green {
        color: $color-emerald;
        &:before {
            border: 1px solid $color-emerald;
        }
        &:after {
            border-color: $color-emerald;
        }
    }
}

//customized style for bootstrap 4 switch
/* The switch - the box around the slider */

.switch {
    position: relative;
    display: block;
    width: 34px;
    height: 12px;
    /* Hide default HTML checkbox */
    input {
        z-index: 1;
        position: absolute;
        opacity: 0;
        &:checked + .slider {
            background-color: $color-emerald;
            box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);
        }
        &:focus + .slider {
            box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);
        }
        &:focus + .slider:before {
            box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.2);
        }
        &:checked + .slider:before {
            -webkit-transform: translateX(21px);
            -ms-transform: translateX(21px);
            transform: translateX(21px);
        }
    }
    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color-tertiary;
        background-color: #CCC;
        -webkit-transition: .4s;
        transition: .4s;
        &:before {
            position: absolute;
            content: "";
            height: 18px;
            width: 18px;
            left: -2px;
            bottom: -3px;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
            background-color: $color-base;
            background-color: #9f9f9f;
            -webkit-transition: .4s;
            transition: .4s;
        }
    }
    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
        &:before {
            border-radius: 50%;
        }
    }
    .status {
        padding-left: 45px;
    }
    input:disabled {
        &+.slider.round {
            opacity: 0.5;
        }
    }
}

.form-control::placeholder {
    color: $color-placeholder;
    opacity: 1;
}

z-export-details {
.zing-ui .form-control::placeholder {
    color: $color-placeholder !important;
    opacity: 1;
}
}

.form-control::-webkit-input-placeholder /* Chrome/Opera/Safari */ {
    color: $color-placeholder;
    opacity: 1;
}

.form-control::-moz-placeholder /* Firefox 19+ */ {
    color: $color-placeholder;
    opacity: 1;
}

.form-control:-ms-input-placeholder /* IE 10+ */ {
    color: $color-placeholder;
    opacity: 1;
}

.form-control::-ms-input-placeholder /* Edge */ {
    color: $color-placeholder;
    opacity: 1;
}

.form-control:-moz-placeholder /* Firefox 18- */ {
    color: $color-placeholder;
    opacity: 1;
}

.form-control:-ms-input-placeholder /* IE 10+ */ {
    color: $color-placeholder;
    opacity: 1;
}

input,
textarea,
select {
    &.ng-invalid {
        &.ng-touched,
        &.ng-dirty {
            border-color: $color-ruby;
        }
    }
}

.zing-ui {
    input,
    textarea,
    select,
    .ng-select.ng-select-single,
    .ng-select.ng-select-multiple {
        &.ng-invalid {
            &.ng-touched,
            &.ng-dirty {
                border-color: $color-zing-red;
                .ng-select-container {
                    border-color: $color-zing-red !important;
                }
            }
        }
    }
}

z-export-details {
    ng-select {
        &.ng-invalid {
            &.ng-touched,
            &.ng-dirty {
                .ng-select-container{
                    border-color: $color-ruby;
                }
            }
        }
    }
}

.phoneNumber,
#phoneNumber {
    &.ng-invalid {
        &.ng-touched,
        &.ng-dirty {
            border: $color-ruby solid 1px !important;
            border-left: 1px solid $color-tertiary !important;
        }
    }
}

.zing-ui {
    .phoneNumber,
    #phoneNumber {
        &.ng-invalid {
            &.ng-touched,
            &.ng-dirty {
                border: solid 1px $color-zing-red !important; 
                border-left: 1px solid $color-tertiary !important;
            }
        }
    }
}

input[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
}
.typeahead-control {
    input {
        &.ng-invalid {
            &.ng-touched,
            &.ng-dirty {
                border-color: $color-tertiary;
            }
        }
    }
}

:not(.fw-container) + {
    input,
    textarea {
        &.form-control.contains-forbidden-words {
            color: $color-zing-red;
        }
    }
}

.forbidden-words-input-info {
    font-size: 12px;
    line-height: 14px;
}

/************ Start of ng-select styles ************/
.ng-select .ng-select-container {
    @extend .form-select;
    min-height: 55px;
    padding-right: 20px;
    & .ng-arrow-wrapper {
        display: none;
    }
}
.big-select .ng-select.ng-select-single .ng-select-container {
    min-height: 55px;
}
.big-select .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: 17px;
}
.ng-dropdown-panel-items {
    .custom-control-label {
        margin-bottom: 0;
        color: $color-onyx;
    }
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: 20px;
    color: $color-placeholder !important;
    opacity: 1;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    &, &.ng-option-marked {
        background-color: transparent;
    }
}
.ng-dropdown-panel .scroll-host {
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0);
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
    }
    &::-webkit-scrollbar-thumb {
        background-color: $color-primary;
        border-radius: 2px;
        cursor: pointer;
    }
}

#appraisals-container, .diamond-details, .colored-stone-details,
z-shipping-preset-form {
    .ng-select.ng-select-single, .ng-select.ng-select-multiple{
        &.ng-invalid {
            &.ng-touched,
            &.ng-dirty {
                .ng-select-container {
                    border-color: $color-ruby;
                }
            }
        }
        .ng-select-container {
            height: 55px;
            color: $color-onyx;
            .ng-value-container {
                .ng-placeholder {
                    top: initial;
                    color: $color-placeholder !important;
                    opacity: 1;
                }
            }
        }
    }
}

z-shipping-preset-form {
    .ng-select.ng-select-single {
        .ng-select-container {
            .ng-value-container {
                .ng-input {
                    top: auto;
                    bottom: auto;
                }
            }
        }
    }
}

z-insurance-only-shipment {
    .ng-select.ng-select-single {
        .ng-select-container {
            min-width: 0 !important;
        }
    }
    // long options are wrapped in new line
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        white-space: normal;
    }
}

#shipment-summary {
    .filter-field-set {
        min-width: 110px;
        .ng-select.select-filter {
            .ng-select-container {
                background-image: none !important;
                background: $color-white url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='50' fill='%236699CC'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat right 0.75rem top 16px/11px 7px !important;
                padding-left: .5rem !important;
                border: none;
                height: 42px;
                .ng-value-container {
                    color: $color-header-blue !important;
                    .ng-placeholder {
                        padding-left: 6px;
                        color: $color-header-blue !important;
                        font-weight: bold;
                    }
                    .ng-value-label {
                        font-size: .9rem;
                        font-weight: bold;
                        background-color: $color-white;
                        color: $color-header-blue;
                    }
                    .ng-input {
                        width: min-content;
                    }
                }
            }
        }
    }
    .ng-dropdown-panel {
        width: auto;
        .ng-dropdown-panel-items {
            .ng-option .custom-control-label {
                padding-top: .2rem;
            }
            .ng-option.ng-option-selected,
            .ng-option.ng-option-selected.ng-option-marked {
                background-color: $color-white !important;
            }

            .ng-option.ng-option-child {
                padding-left: .8rem;
            }
        }
    }
}

z-shipping-preset-form {
    .ng-select.ng-select-single, .ng-select.ng-select-multiple {
        .ng-select-container {
            border-color: $color-tertiary;
            //for cursor to be separated from text
            padding-left: 1px;
        }

        &.ng-select-focused .ng-select-container {
            border-color: $color-tertiary;
            box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1) !important;
        }
    }
}

z-one-page-shipment {
    .basic-control {
        box-shadow: none;
    }
    .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
        border-color: $color-light-gray2;
    }
    .address-location .custom-checkbox {
        input:disabled {
            &:before, &~.custom-control-label {
                opacity: 0.5;
            }
        }
    }
}

z-shipping-billing {
    .basic-control {
        box-shadow: none;
    }
    .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
        border-color: $color-light-gray2;
    }
}

z-valuation, z-user-preferences, z-watch-details {
    .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
        .ng-value {
            background-color: $color-table-widget-light-blue !important;
            margin: 1px !important;
            padding-right: 10px;
            .ng-value-label, .ng-value-icon.left {
                color: $color-black !important;
                border-right: $color-table-widget-light-blue !important;
                overflow: hidden;
                white-space: break-spaces;
                padding: 5px 2px 0 2px;
            }
            .ng-value-icon.left:hover {
              background-color: $multiselect-light-blue-hover !important;
            }
        }
    }
    .ng-select .ng-clear-wrapper {
        margin: 0 10px;
    }
}

z-user-preferences, z-watch-details {
    .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
        white-space: break-spaces !important;
    }
}
/************** End of ng-select styles **************/

// placeholder color for appraisal module
* {
    &::placeholder {
        color: $color-placeholder;
        opacity: 1;
    }

    &::-webkit-input-placeholder /* Chrome/Opera/Safari */ {
        color: $color-placeholder;
        opacity: 1;
    }

    &::-moz-placeholder /* Firefox 19+ */ {
        color: $color-placeholder;
        opacity: 1;
    }

    &:-ms-input-placeholder /* IE 10+ */ {
        color: $color-placeholder;
        opacity: 1;
    }

    &::-ms-input-placeholder /* Edge */ {
        color: $color-placeholder;
        opacity: 1;
    }

    &:-moz-placeholder /* Firefox 18- */ {
        color: $color-placeholder;
        opacity: 1;
    }

    &:-ms-input-placeholder /* IE 10+ */ {
        color: $color-placeholder;
        opacity: 1;
    }
}

#showWorkingWidget {
    .dropdown-menu {
        left: 0 !important;
        right: 0 !important;
        margin-left: auto;
        margin-right: auto;
        max-width: 320px;
    }
    @media (max-width: 1200px) {
        .dropdown-menu {
          width: 60%;
          max-width: 70%;
        }
      }
}

.ecom-integration-root {
    .label-bold {
        font-family: 'Roboto-Bold';
        color: $color-zing-brown;
    }
    a.link {
        font-family: 'Roboto-Bold';
        color: $color-header-blue !important;
        text-decoration: underline !important;
    }
}

.required-label {
    &:after {
        padding-left: 0.2rem;
        content: '*';
        color: $color-zing-red;
    }
}

.roboto-bold-label {
    font-family: 'Roboto-Bold';
    font-size: .87rem !important;
    margin-bottom: .5rem !important;
    color: $color-text-10 !important;
}

.roboto-checkbox-label {
    font-family: 'Roboto-Regular';
    font-size: .87rem !important;
    color: $color-text-10 !important;
    font-weight: normal !important;
    line-height: 150% !important;
}

z-armored-transport {
    .form-control {
        box-shadow: none !important;
    }
    .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
        border-color: #495057 !important;
    }
}

z-address-validation-confirmation {
    .form-control.ng-invalid.hide-input-error {
        border-color: $color-light-gray2 !important;
    }
}

.fw-container {
    position: relative;
    .fw-warning-icon {
        left: auto;
        right: 10px;
        position: absolute;
        color: $color-zing-red;
        font-size: 1.4rem;
        margin: auto;
        top: 0;
        bottom: 0;
        height: fit-content;
    }
    input, input:focus {
        &.contains-forbidden-words {
            padding-right: 2.2rem;
            color: $color-zing-red;
            caret-color: $color-text-10;
        }
    }
}

z-one-page-shipment,
z-security-seals-modal,
z-insurance-summary,
z-commodities-modal,
z-export-details-documents,
z-tax-grid,
z-edit-woocommerce-estore,
z-ecom-estore-modal,
z-estore-origin-defaults,
z-estore-general-defaults,
z-estore-shipment-methods {
    .form-group small {
        color: $color-zing-red;
        line-height: 150%;
    }
}

z-diamond-details {
    .basic-control {
        &[readonly] {
          border: 1px solid $color-tertiary;
          box-shadow: none;
          height: initial;
          padding: 0.45rem 0.9rem;
          color: $color-onyx;
        }
    }
}