// Style file containing generic styles of carousel components
@import '../utils/_variables.scss';
// Below class provides animation sort of behavior. 
// Expand/Hide hidden carousel slides on click of a button
.carousel-expanded {
    .ngucarousel {
        .ngu-touch-container {
            .ngucarousel-items {
                display: block !important;
                .item {
                    width: 33.3%;
                }
            }
        }
    }
}

//ngx-carousel
.tile {
    box-shadow: none !important;
    cursor: pointer;
}

.leftRs,
.rightRs {
    display: none !important;
}

.ngucarouselPoint {
    list-style-type: none;
    display: flex;
    padding-left: 0.5em;
}

.ngucarouselPoint li {
    background-color: #DDD;
    border-radius: 50%;
    height: 0.5em;
    margin: 0 1em 0 0;
    width: 0.5em;
}

.ngucarouselPoint li.active {
    background-color: $color-primary;
}

//Frequently Contacted
z-frequent-contacts {
    .ngucarousel {
        .ngu-touch-container {
            .ngucarousel-items {
                .item {
                    flex: 0 0 auto!important;
                    padding: 10px 0!important;
                    width: 78px!important;
                }
            }
        }
    }
}
.dashboard-options .ngucarousel .ngu-touch-container {
    overflow-x: auto !important;
}

@media (max-width: 991.98px) {
    .carousel-expanded {
        .ngucarousel {
            .ngu-touch-container {
                .ngucarousel-items {
                    display: block !important;
                    .item {
                        width: 100%;
                    }
                }
            }
        }
    }
}

//custom style for help videos on landing page
.helpVideoLandingPage {
    #videoCarousel {
        .tile {
          cursor: default;
        }
        .ngucarousel {
          overflow: visible;
          .ngu-touch-container {
            overflow: hidden;
          }
        }
      }
}

.marketDataWidget {
    #stockTickerCarousel {
        .ngucarousel {
            overflow: visible;
            .ngu-touch-container {
              overflow: hidden;
              margin-left: 1rem;
              margin-right: 1rem;
            }
        }
    }
}