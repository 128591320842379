@import '../utils/variables';

.form-check {
    width: fit-content !important;
    .form-check-input:not(.zing-ui),
    .form-check-input.zing-ui {
        outline: none;
        border: 1px solid #888 !important;
        vertical-align: middle;
        &:disabled {
            background-color: $color-background-3;
        }
    }
    .form-check-input:not(.zing-ui) {
        height: 20px;
        width: 20px;
        &:checked {
            background-color: $color-primary;
        }
        &:focus {
            box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1)!important;
            border: 1px solid #ced4da;
        }
    }
    .form-check-input.zing-ui {
        height: 1rem;
        width: 1rem;
        &:checked {
            background-color: $color-dark-blue;
            border-color: $color-dark-blue;
        }
        &:focus {
            box-shadow: 0 0 0 1px $color-white, 0 0 0 0.1rem #46638f40!important;
        }
    }
    .form-check-label {
        padding-left: 10px !important;
        line-height: 24px !important;
        height: fit-content;
    }
}
