.jmcc-dashboard-modal {
  .modal-content {
    border-radius: .8rem !important;
  }
}

@media only screen and (max-device-width: 960px) and (orientation: landscape) {
  .zing-ui-tooltip {
    display: none;
  }
}