ngb-pagination {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 30px;
    justify-content: center;
    margin-top: 15px;
      ul.pagination {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
        align-items: center;
        li, li.disabled {
          .page-link {
            margin-right: 10px;
            border: 0.5px solid #CCC!important;
            background-color: #E9E9E9;
            color: #B1ABAB!important;
            border-radius: 50% !important;
            text-decoration: none !important;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 25px;
            height: 25px;
          }
        }
        li.active {
          .page-link {
            color: #fff!important;
            border: 1px solid #615E9B!important;
            font-family: Roboto-Bold;
            background-color: #615E9B!important;
          }
        }
      }
    }