.limits-popover-container {
  ngb-popover-window {
    max-width: 275px!important;
    width: 275px!important;
    padding-bottom: 1rem;
    max-height: 500px;
    height: 50px;
    border: none;
    .popover-body {
      background-color: white;
      color: $color-onyx;
      margin: 0 !important;
      padding: 0 !important;
      border: 1px solid lightgray;
      min-height: 50px;
    }
  }
}

.color-text-secondary {
  color: $color-text-secondary !important;
}

.font-family-Roboto-Condensed {
  font-family: "Roboto Condensed" !important;
}

.negative-ml {
  margin-left: -1.5rem;
}

.h-24 {
  height: 24px;
}