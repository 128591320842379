@import '../utils/mixins';

//Call to margin helper mixin for generating generic margins
@include margin-helper(0, 0);
@include margin-helper(1, .5rem);
@include margin-helper(2, 1rem);
@include margin-helper(3, 1.5rem);
@include margin-helper(4, 2rem);
@include margin-helper(5, 2.5rem);
@include margin-helper(6, 3rem);
@include margin-helper(0em, 0);
@include margin-helper(1em, 1em);
@include margin-helper(2em, 2em);
@include margin-helper(3em, 3em);
@include margin-helper(4em, 4em);
@include margin-helper(5em, 5em);

//Call to padding helper mixin for generating generic padding
@include padding-helper(0, 0);
@include padding-helper(1, .5rem);
@include padding-helper(2, 1rem);
@include padding-helper(3, 1.5rem);
@include padding-helper(4, 2rem);
@include padding-helper(5, 2.5rem);
@include padding-helper(6, 3rem);
@include padding-helper(7, 3.5rem);

.mt-025rem {
  margin-top: 0.25rem !important;
}

.mt-1rem {
  margin-top: 1rem !important;
}

.mr-1rem {
  margin-right: 1rem !important;
}

.mb-1rem {
  margin-bottom: 1rem !important;
}

.ml-025rem {
  margin-left: 0.25rem !important;
}

.ml-1rem {
  margin-left: 1rem !important;
}

.mt-2rem {
  margin-top: 2rem !important;
}

.mt-3rem {
  margin-top: 3rem !important;
}

.mt-4rem {
  margin-top: 4rem !important;
}

.mr-2rem {
  margin-right: 2rem !important;
}

.mb-2rem {
  margin-bottom: 2rem !important;
}

.mb-3rem {
  margin-bottom: 3rem !important;
}

.mb-4rem {
  margin-bottom: 4rem !important;
}

.ml-2rem {
  margin-left: 2rem !important;
}

.pt-1rem {
  padding-top: 1rem !important;
}

.pr-1rem {
  padding-right: 1rem !important;
}

.pb-1rem {
  padding-bottom: 1rem !important;
}

.pl-1rem {
  padding-left: 1rem !important;
}

.pb-2rem {
  padding-bottom: 2rem !important;
}
.pb-3rem {
  padding-bottom: 3rem !important;
}
.pt-2rem {
  padding-top: 2rem !important;
}

.pr-2rem {
  padding-right: 2rem !important;
}

.pl-2rem {
  padding-left: 2rem !important;
}

.p-2rem {
  padding: 2rem !important;
}

.p-1rem {
  padding: 1rem !important;
}
