@import '../utils/_variables.scss';

.back-links-wrap {
  .back-link,
  .link-shipment-details {
    padding: 0.2em 1em;
    font-family: Roboto-Regular;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: $color-primary;
    text-decoration: underline !important;

    #shippingDashboardIcon {
      margin-right: 6px;
    }

    &.go-back-link {
      align-self: center;
      margin-top: -1px;
    }
  }
}

.new-shipmentpage {
  .back-link,
  .link-shipment-details {
    font-family: 'Roboto-Bold';
    color: $color-header-blue !important;
  }
}


.link-color {
  color: $widget-blue-color !important;
}

.ags .link-color {
  color: $color-ags-light !important;
}
