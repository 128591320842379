.p-paginator {
  border: none !important;
}

.p-datatable-thead th {	
  font-size: 14px;	
  font-weight: bold !important;	
  font-family: Roboto, sans-serif !important;	
  color: $color-zing-brown !important;	
}	

.p-datatable-tbody td {	
  color: $color-zing-brown !important;	
  font-family: Roboto, sans-serif !important;	
}

.p-paginator-pages .p-highlight {
    color: $color-white !important;
    background-color: $color-dark-blue !important;
    border-color: $color-dark-blue !important;
}

//shipment-quote
.shipment-quotes .buy-column {
    text-align: center !important;
}

@media (min-width: 991px) {
    .shipment-quotes .buy-column {
        background-color: $color-base !important;
        background-clip: content-box !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

// labgrown-list
.labgrown-list {
  .grading-lab {
    color: $color-header-blue !important;
    font-family: 'Roboto-Regular' !important;
  }
  .price {
    font-weight: 700 !important;
    font-family: 'Roboto-Bold' !important;
    color: $color-green-success-message !important;
  }
}

// global table styles in Zing
table.p-datatable-table {
  color: $color-primary;
  th {
    border: none !important;
  }
  td {
    border: none !important;
    vertical-align: middle !important;
    color: $color-blue;
  }
  .form-select{
    color: $color-primary;
    height: 47px;
    border-color: $color-tertiary;
    &:focus {
      box-shadow: none;
      border-color: $color-tertiary;
    }
  }
}

th.break-spaces, td.break-spaces {
  white-space: break-spaces;
}

.p-datatable-thead th.text-wrap, .p-datatable-thead th.text-wrap .header-text,
.p-datatable-tbody td.text-wrap.p-element {
  text-wrap: auto !important;
}

.pr-05rem {
  padding-right: 0.5rem !important;
}

.h-100px {
  height: 100px;
}