@import '../utils/_variables.scss';

.alert:not(.zing-ui) {
	color: $color-white;
	font-size: 15px;
	font-weight: 500;
	letter-spacing: 0.30px;
	line-height: 20px;
	padding-left: 3em;
	text-align: left;

	ul {
		padding: 0;
		margin: 0;
		li {
			list-style: none;
		}
	}
}

.alert-success:not(.zing-ui) {
  background-color: $color-emerald;
  &::before {
	content: "\2713";
	position: absolute;
	left: 1.5em;
	font-size: 15px;
  }
  button {
    .cross-circle-sign::before {
      color: $color-emerald;
    }
  }
}

.alert-danger:not(.zing-ui) {
	background-color: $color-ruby;
	&:not(.zing-ui)::before {
		content: "\0021";
		position: absolute;
		left: 1.3em;
		font-size: 17px;
	  }
	button {
		.cross-circle-sign::before {
			color: $color-ruby;
		}
	}
}

.alert-warning:not(.zing-ui)
{
	background-color: $color-primary;
	&::before {
		content: "\0021";
		position: absolute;
		left: 1.3em;
		font-size: 17px;
	  }
	button {
		.cross-circle-sign::before {
			color: $color-ruby;
		}
	}
	width: 100%;
}

.stone-details-popup, .valuation {
	.alert-info {
    background-color: $color-zing-btn-outline;
    &::before {
      content: "\e009";
      position: relative;
      top: 2px;
      font-size: 15px;
      font-family: dripicons-v2;
    }
    button {
      .cross-circle-sign::before {
        color: $color-zing-btn-outline;
      }
    }
  }
}

button.close {
	color: $color-white;
	font-size: 0.6rem !important;
	top: 50% !important;
	padding-right: 10px !important;
	transform: translate(-50%, -50%) !important;
	opacity: 1;
	text-shadow: 0 1px 0 $color-white;
	filter: invert(1);
}

#appraisal-dashboard,
z-jewelry-item-modal,
z-jmcc-dashboard-page,
z-add-diamond,
z-memo-confirmation-modal,
z-shipping-presets,
z-customize-columns-modal {
	button.close {
		filter: none !important;
	}
}

z-insurance-only-shipment,
z-payment-method-modal,
z-upload-certificate-modal-v2,
z-upload-certificate-modal,
z-exemption-preview-modal {
	button.close {
		filter: invert(0);
	}
}

z-save-shipment,
#appraisal-dashboard,
z-jmcc-dashboard-page,
z-jewelry-item-modal,
z-add-diamond,
z-memo-confirmation-modal,
z-shipping-presets,
z-contacts,
z-payment-method-modal,
z-customize-columns-modal,
z-shipment-actions-modal,
z-upload-certificate-modal-v2,
z-upload-certificate-modal,
z-exemption-preview-modal {
	button.close {
		font-size: 1.35rem !important;
		top: auto !important;
		padding-right: 0px !important;
		transform: none !important;
	}
}

.close:not(:disabled):not(.disabled):not(.zing-ui):hover, 
.close:not(:disabled):not(.disabled):not(.zing-ui):focus {
	color: $color-white;
}


.success-checkmark:after {
	content: '✔';
    color: #008563;
    position: absolute;
    width: 20px;
    height: 20px;
    text-align: center;
    border: 2px solid #008563;
    background: white;
    border-radius: 50%;
    font-size: 15px;
    line-height: 18px;
}

.no-content-mark:after {
    content: 'x';
    color: #C01F5D;
    position: absolute;
    width: 20px;
    height: 20px;
    text-align: center;
    border: 2px solid #C01F5D;
    background: white;
    border-radius: 50%;
    line-height: 14px;
    padding-right: 1px;
    font-size: 15px;
}

.quotes-alert {
	font-size: 15px !important;
	font-family: roboto-regular !important;
	align-items: baseline;
	padding: 15px;
	color: $color-quote-error !important;
	border: 1px solid $color-quote-error;
	width: 100%;
	text-align: left;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 15px;
	border-radius: 5px;
  }

  .quotes-alert-bolder {
	  font-weight: 1000;
  }

.zing-ui.order-details, .zing-ui .diamond-search-grid, .zing-ui.export-details-root, z-shipment-label, z-insurance-only-shipment,
	z-insurance-summary, z-schedule-pickup-form, z-import-contacts-modal, z-fedex-integration-modal,
	z-export-details-documents, z-file-claim, z-shipment-summary {
		alert {
			.alert.alert-danger, .alert.alert-success {
				padding-left: 2.25rem !important;
			}
		}
}

z-schedule-pickup-form {
	alert {
		.alert.alert-warning {
			padding-left: 2.25rem !important;
			background-color: $color-badge-background !important;
			color: $color-warning-accent !important;
		}
	}
} 

z-shipping-information,
z-shipment-summary {
	alert {
		.alert {
			color: $color-white !important;
			&.alert-danger {
				background-color: $color-red-danger-message !important;
				&::before {
					content: 'x';
					position: absolute;
					left: 1.5em;
					font-size: 15px;
				}
			}

			&.alert-success {
				background-color: $color-green-success-message !important;
				&::before {
					content: "✓";
					position: absolute;
					left: 1.5em;
					font-size: 15px;
				}
			}
		}
	}
}

z-shipping-dashboard, z-supplier-logo-upload-modal, z-cl-commercial-lines-dashboard {
	alert { 
		.alert {
			padding-left: 2.5rem !important
		}
	}
}

z-edit-woocommerce-estore {
	alert {
		.alert {
			@media(max-width: 768px) {
				margin-top: 1rem;
			}

			&.alert-danger {
				color: $color-white !important;
				background-color: $color-red-danger-message !important;

				&::before {
					content: '⚠' !important;
					left: 1rem !important;
					font-size: 20px !important;
				}
			}

			&.alert-success {
				color: $color-white !important;
				background-color: $color-green-success-message !important;

				&::before {
					content: "✓" !important;
					left: 1rem;
					font-size: 20px;
				}
			}

			&.alert-warning {
				color: $color-text-12 !important;
				background-color: $color-badge-background !important;

				&::before {
					content: "⚠" !important;
					left: 1rem;
					font-size: 20px;
				}
			}
		}
	}
}

z-save-draft-modal, z-user-preferences, z-preview-appraisal, z-email-preview, z-basic-modal, z-address-validation-confirmation, z-shipment-suggested-addresses,
z-international-important-notice, z-terms-conditions-modal, z-schedule-pickup-form, z-appraisal-confirm-modal, z-diamond-details, z-colored-stone-details,
z-pearl-details, z-invite-sent-model {
	button.close {
		font-size: 1.35rem !important;
		display: contents;
		opacity: 0.75 !important;
		filter: invert(0);
		span {
			opacity: 0.75 !important;
		}
		:hover {
			opacity: 1 !important;
		}
	}
}

z-address-validation-confirmation,
z-shipment-suggested-addresses,
z-international-important-notice,
z-terms-conditions-modal,
z-schedule-pickup-form,
z-payment-method-modal,
z-upload-certificate-modal-v2,
z-upload-certificate-modal,
z-exemption-preview-modal {
	button.close:hover {
		color: #4A4A4A !important;
		opacity: 0.75 !important;
	}
}

z-shipping-presets,
z-contacts {
	button.close {
		margin: -1rem 0 -1rem auto;
	}
}

.new-addresses .zing-ui, .search-layout-outer, .marketplace-dashboard , .zing-ui.export-details-root, .bannerAlert {
	.alert.alert-danger, .alert.alert-success, .alert.alert-warning {
		&::before {
			display: none;
		}
	}
}

.bannerAlert {
	.alert.alert-info {
		&::before {
			display: none;
		}
	}
}
