@import '../utils/variables';

.registration-container .heading-20-bold-primary, 
.registration-container .heading-16-primary,
.registration-container .clear_address_details,
.reset-password-step .heading-20-bold-primary,
.reset-password-step .heading-30-bold-primary,
.reset-password-step .heading-14-bold-primary,
.registration-container a, 
.registration-container a:hover,
.verification a, 
.verification a:hover {
    color: $color-zing-blue !important;
}
.registration-container .form-select {
        background: $color-white url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%230058a6' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center;
        background-size: 8px 10px;
}

.reset-password-step .btn-primary-purple {
    @extend .btn-zing-blue;
}

a.text-zing-blue, a.text-zing-blue:hover {
    color: $color-zing-blue !important;
}

.registration-page .alert-warning {
    background-color: $color-zing-blue !important;
}