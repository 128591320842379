// IMPORTING UTILITY SCSS FILES
@import './utils/fonts';
@import './utils/generic';
@import './utils/mixins';
@import './utils/spacing-helpers';
@import './utils/variables';
@import './utils/animations';

// IMPORTING LAYOUT SCSS FILES
@import './layout/container';
@import './layout/footer';
@import './layout/header';
@import './layout/modal';
@import './layout/sidenav';

// IMPORTING COMPONENT SCSS FILES
@import './components/_grid';
@import './components/alerts';
@import './components/buttons';
@import './components/carousel';
@import './components/forms';
@import './components/form-check';
@import './components/font-sizes';
@import './components/heading';
@import './components/ng-slider';
@import './components/payment-card';
@import './components/popover';
@import './components/progress-meter';
@import './components/spinner';
@import './components/table';
@import './components/tabs';
@import './components/tiles';
@import './components/address-search';
@import './components/font-family';
@import './components/kendo-grid';
@import './components/pagination';
@import './components/back-links';
@import './components/appraisal-custom';
@import './components/_registration_custom.scss';
@import './components/kendo-grid-new/kendo-grid-tooltip.scss';
@import './components/timepicker';
@import './components/datepicker';
@import './components/claim';
@import './components/progress-stepper';
@import './components/dropdownmenu';
@import './components/primeng-table';

@import './components/_modern'; // Webkit styles

// IMPORTING COMMERCIAL LINES SCSS FILES
@import 'src/app/modules/membership/modules/commercial-lines/styles/table-information';

// MARKETPLACE STYLES
@import '../../app/modules/marketplace/styles/marketplace-shared';
@import '../../app/modules/marketplace/shopping-cart/styles/marketplace-modal-shared';
@import '../../app/modules/membership/modules/shipping/styles/common';

// MEMO STYLES
@import '../../app/modules/memo/styles/memo-shared.scss';

// JEWELER PAGES STYLES
@import '../../app/modules/jeweler-pages/styles/jeweler-pages.shared.scss';

// KENDO FILTER POPUP SCSS
@import "./components/kendo-grid-new/kendo-grid-popup.scss";

// OWL-CAROUSEL 
@import "./components/owl-carousel/owl-carousel";

// JMCC DASHBOARD STYLES
@import '../../app/modules/jmcc-dashboard/styles/jmcc-dashboard-modal-shared';

html,
body {
  .cdk-overlay-container {
    z-index: 2000 !important;
  }

  font-family: "Roboto-Regular";
  // font-size: 14px;
  // line-height: 16px;
  color: $color-onyx;
  position: relative;
  left:0;

  .content-wrap,
  z-idex-search,
  z-idex-search-ags,
  z-footer,
  .whistle-sidenav-mini,
  modal-container,
  z-export-details {
    font-size: 14px;
    line-height: 16px;
  }
}

body.modal-open {
  padding-right: 0 !important;
  overflow: hidden !important;

  .member-layout,
  #landing-page {
    filter: blur(10px);
  }

  .add-diamond-modal .modal-content {
    padding: 0;
    margin: 0
  }

  .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.4);

    &.show,
    &.fade {
      opacity: 0.2;
    }
  }

  &.zing-ui-modal-open {
    .modal {
      width: 100vw;
      height: 100vh;
    }

    .modal-backdrop {
      background-color: $color-black;

      &.show,
      &.fade {
        opacity: 0.65;
      }
    }
  }

  &.save-search-modal-open,
  &.search-preset-modal-open {

    .modal-backdrop,
    .modal {
      z-index: 10000;
    }
  }
}

//Keyboard accessibility change for anchor selection
a:focus {
  text-decoration: underline;
}

.mobile-view {
  display: none;
}

input::-ms-clear {
  display: none
}

.marketplace-dashboard,
.supply-orders,
.document-form,
.appraisals-dashboard,
.jmcc-dashboard-container,
.shipping-dashboard,
.shipment-quotes,
.shipment-reconciliation,
.transactions-listing,
.memo-dashboard,
.memo-details-grid,
.paid-statements,
.statement-details,
.marketplace-page {
  @import "../../styles/_scss/components/kendo-grid-new/kendo-grid.scss";
}

// scan form specific grid
.document-form {
  @import "../../styles/_scss/components/document-form-modal-grid.scss";
}