.k-tooltip {
  border-color: #343a40;
  color: #ffffff;
  background-color: #343a40;
  border-radius: 0.2rem;
  margin: 0;
  padding: 0.4rem 0.8rem;
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  background-repeat: repeat-x;
  outline: 0;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.25;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-align: start;
  align-items: flex-start;
  position: absolute;
  z-index: 12000;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  max-width: 200px;
  opacity: 0.9;
}

.k-tooltip-content {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  line-height: 1.5;
}

.k-tooltip-wrapper .k-tooltip {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03), 0 4px 5px 0 rgba(0, 0, 0, 0.04);
}

.k-tooltip .k-callout {
  color: rgba(0, 0, 0, 0.75);
}

.k-callout {
  width: 12px;
  height: 12px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent;
  box-sizing: border-box;
  position: absolute;
  pointer-events: none;
}

.k-callout-s {
  margin-left: -6px !important;
  border-top-color: currentColor;
  bottom: -12px;
  left: 50%;
  pointer-events: none;
}
