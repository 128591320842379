@import '../utils/variables';

.spinner, .lds-dual-ring {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
}

.spinner {
  background-color: rgba(255,255,255, 0.8);
}

.lds-dual-ring {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin: auto;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid $color-zing-btn-blue;
  border-color: $color-zing-btn-blue transparent $color-zing-btn-blue transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
.centered-spinner .lds-dual-ring {
  height: 50px;
  margin: auto;
  padding: 0 !important;
  margin: 0;
  position: fixed;
  top: 50%;
  left: 50%; 
}

.positioned-spinner {
  position: relative;
  transform: scale(0.6);
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
