@import '../utils/variables';

.company-logo {
  display: inline-block;

  .quartz-logo {
    height: 7em;
    vertical-align: baseline;
  }
}


// responsive handliing of header
@media(max-width: 991.98px){ 
    .company-logo {
      padding: 0;

      .burger-menu {
        height: 25px;
      }
      .quartz-logo {
        height: 55px;
        padding-left: 1em;
        vertical-align: middle;
      }
    }
  }