@import '../utils/_variables.scss';
table:not(.mat-calendar-table, .memo-table, .tax-documents-table, .p-datatable-table) {
  thead {
    font-family: Roboto-Light;
    background-color: #F7F7FB;
    color: $color-primary;
    font-size: 15px;
    line-height: 18px;
    th {
      padding: 1.2rem 1rem !important;
      border: none !important;
    }
  }
  td {
      border: none !important;
      font-size: 14px;
      vertical-align: middle !important;
      padding: 1rem !important;
      color: $color-blue;
  }
  .form-select{
    color: $color-primary;
    height: 47px;
    border-color: $color-tertiary;
    &:focus {
      box-shadow: none;
      border-color: $color-tertiary;
    }
  }
}



.table-striped tbody tr:nth-of-type(odd) {
  background-color: $color-table-odd-row;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: $color-table-even-row;
}

.zing-ui .table {
  --bs-table-striped-bg: $color-black;
}
