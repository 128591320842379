@import '../utils/_variables.scss';

.steps-row {
  padding: 2rem 0 3rem 0;

  .step-container {
    display: flex;

    .step {
      border-radius: 50%;
      border: 2px solid $color-dark-blue-link;
      min-height: 35px;
      min-width: 35px;
      background: $color-dark-blue-link;
    }

    .number {
      padding: 0.55rem 0 0 0.75rem;
      font-size: 0.88rem;
      color: $color-white;
      line-height: 100%;
      font-family: 'RobotoCondensed-Regular';
    }

    .step-text {
      font-size: 1rem;
      min-height: 35px;
      max-width: 80%;
    }

    .step-line {
      height: 100%;
      width: 2px;
      background-color: $color-text-4;
      opacity: 0.75;
    }

    .title-text {
      font-size: 1.5rem;
      font-family: 'RobotoCondensed-Bold';
      line-height: 1.75rem;
      margin-top: .25rem;
    }

    .step-content {
      width: 100%;
    }
  }
}
