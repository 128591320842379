@import '../utils/variables';
@import '../utils/button-mixins';
@import '../utils/mixins';
@import '../utils/highcontrasttheme';

//Chrome browser outline fix
.btn, button {  
    outline: 0;
    cursor: pointer!important;
}
.custom-btn {
    border: 0;
    background-color: transparent;
    padding: 0;
    outline: 0;
    text-align: left;
    cursor: pointer;
}
.font-size-0 {
    font-size: 0;
    height: 0;
}
button:focus,
a[tabindex="0"]:focus {
    border-color: $color-btn-border;
    outline: 0;
    //box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.2)!important;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1) !important;
}
a[tabindex="0"].k-link:focus {
  outline: none;
  box-shadow: none !important;
}
.btn.focus, .btn:focus {
    outline: 0;
    box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1)!important;
}
a:focus {
    text-decoration: underline;
    img {
        box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1)!important;               
    }
}
a.disabled,
a.disabled:hover {
    opacity: 0.6!important;
    cursor: default!important;
}

.btn {
    border-radius: 4px;
    cursor: pointer;  
    text-transform: uppercase;
    width: 100%;
    &:focus {
      box-shadow: 0 0 6px 3px rgba(0,0,0,0.2)!important;
    }
}

.btn:disabled {
    opacity: 0.5;
}

.btn:disabled:hover {
  cursor: no-drop;
}

a {
  cursor: pointer;
}

.btn-sm {
  padding: 0.5em;
  @extend .btn;
  &:focus {
    box-shadow: 0 0 6px 3px rgba(0,0,0,0.2)!important;
  }
}

.btn-md {
  font-size: 14px;
  padding: 1.3em 0.6em;
  @extend .btn;
  &:focus {
    box-shadow: 0 0 6px 3px rgba(0,0,0,0.2)!important;
  }
}

.btn-lg {
  font-size: 1.2em;
  padding: 1.5em;
  @extend .btn;
  &:focus {
    box-shadow: 0 0 6px 3px rgba(0,0,0,0.2)!important;
  }
}

//blue button
.btn-primary-blue {
  background-color: $color-appraisals-blue !important;
  color: $color-white;
  &:focus, &:hover {
    color: $color-white!important;
    box-shadow: 0 0 6px 3px rgba(0,0,0,0.2)!important;
  }
}

//solid button
.btn-primary-purple {
  @include button-color-generator (linear-gradient(160.79deg, $color-primary 0%, $color-turquoise 100%), $color-white, transparent, none);
  background-color: $color-primary;
}
.btn-primary-purple:focus,
.btn-primary-purple:hover {
  color: $color-white!important;
}

//outline button
.btn-secondary-purple {
  @include button-color-generator ($color-primary, $color-white, $color-white, 1.5px solid);
}

.btn-tertiary-purple {
  @include button-color-generator(transparent, $color-primary, $color-primary, 1px solid);
}

.btn-tertiary-blue {
  @include button-color-generator(linear-gradient(160.79deg, $color-primary 0%, $color-quaternary 100%), $color-white, transparent, none);
}

//outline transparent background button
.btn-secondary-transparent {
  @include button-color-generator (transparent, inherit, inherit, 1px solid);
}

//zing branded button
.btn-zing-blue {
  @include button-color-generator ($color-zing-btn-blue, $color-white, transparent, none);
  background-color:$color-zing-btn-blue;
}
.btn-zing-blue:focus,
.btn-zing-blue:hover {
  color: $color-white!important;
}

//outline button
 .btn-secondary-white,
 .btn-secondary-white:active{
    position: relative;
    border: 1px solid transparent;
    background-clip: padding-box;
    display: inline;
    z-index: 1;
    color: $color-primary;
    background: linear-gradient(to bottom right, $color-primary, $color-turquoise)!important;
    border-radius: 4px; 
    overflow: overlay;
    &::after {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background: $color-white;
      content: '';
      z-index: -1;
      border-radius: 4px;
    } 
}

// Used for styling next arrows inside buttons
.button-content {
  height: 1em;
  margin-left: 0.5em;
  margin-top: -0.3em;
}

.btn-md-img {
  padding: 0;
  text-transform: capitalize;
}

.zing-btn-primary {
  @include zing-button-primary($widget-blue-color, $color-white);
}

.zing-btn-outlined {
  @include zing-button-outlined($widget-blue-color, $color-white);
}

.ags .zing-btn-primary {
  @include zing-button-primary($color-ags-light, $color-white);
}

.ags .zing-btn-outlined {
  @include zing-button-outlined($color-ags-light, $color-white);
}

button.close {
  background-color: transparent;
  border: 0;
  text-shadow: 0 1px 0 $color-white;
  font-weight: 700;
  &:hover {
    color: gray;
    opacity: 0.5;
  }
}

.show>.btn-outline-primary.dropdown-toggle {
  color: $color-white;
}