z-shipment-tracker,
z-address-book-search,
z-base-shipment-location,
z-edit-estore,
z-edit-woocommerce-estore,
z-estore-origin-defaults,
z-estore-general-defaults,
z-estore-shipment-methods,
z-ecom-estore-modal,
z-brinks-location,
z-address-validation-confirmation {
  .zing-ui .dropdown-menu {
    width: 100%;
    max-height: 320px;
    overflow-y: auto;
  }
}

z-address-validation-confirmation {
  .zing-ui .dropdown-menu {
    max-width: calc(100% - 1.5rem);
  }
}

z-shipment-tracker {
  .zing-ui .dropdown-menu {
    max-width: calc(100% - 3.95rem);
  }
  @media (max-width: 991px) {
    .zing-ui .dropdown-menu {
      max-width: calc(100% - 1.9rem);
    }
  }
}

z-dashboard-nav {
  z-shipment-tracker {
    .zing-ui .dropdown-menu {
      will-change: unset !important;
      min-width: 175px;
    }
    .zing-ui .dropdown-item {
      word-break: keep-all !important;
      padding: 5px !important;
    }
    @media (max-width: 991px) {
      .zing-ui .dropdown-menu {
          min-width: 224px;
      }
    }
  }
}