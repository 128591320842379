/**
    Import styles from webkit https://zing-ui.azurewebsites.net
    Theme that is used is - modern
    Needed imports: icons and app-modern styles
    Styles used during development and transition to webkit: app-modern-safe
    (This will be replaced with app-modern once transition to webkit is completed)
    Local app-moder-safe and icons will be referenced from site once PROD site is present as well
*/

@import 'src/styles/_modern/app-modern-safe.min'; // local version of app-modern-safe
@import 'src/styles/_modern/icons.min'; // local version of icons
// @import url('https://zing-ui.azurewebsites.net/assets/css/app-modern-safe.min.css'); // TODO: WEBKIT update after webkit is deployed to PROD and DEV
// @import url('https://zing-ui.azurewebsites.net/modern/assets/css/icons.min.css');

// TODO: WEBKIT move to a button from webkit - pending webkit changes
.zing-ui {
  @import "src/styles/_scss/utils/variables";

  &#notification-list-dashboard .dropdown-lg {
    width: 300px;
  }

  .btn-dashboard {
    width: 130px;
    margin: auto;
    border: $color-white solid 2px;
    border-radius: 5px;
    display: block;
    font-weight: 700;
    min-width: fit-content;
    white-space: nowrap;
  }

  .flex-direction-column {
    flex-direction: column;
  }
  .slideout-menu {
    left: -450px;
  }
  &#shippingWidget .dropdown-menu {
    max-height: 150px;
    overflow-y: auto;
    width: 100%;
    z-index: 5000 !important;
    min-width: 10rem;
    border: 2px solid rgba(0,0,0,.15);

    .dropdown-item ngb-highlight {
      font-family: 'Roboto-Regular', sans-serif;
      span.ngb-highlight {
        text-decoration: underline;
        font-weight: 500;
      }
    }
  }

  .dropdown-item {
    white-space: pre-wrap;
    font-size: 12px;
    word-break: break-word;
  }


}

  @media (min-width: 1500px) {
    .jmicard-content {
        min-height: 240px !important;
    }
  }

// Todo: Webkit - remove once webkit is fixed
.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// shared webkit styles
z-document-form-modal,
z-idex-search,
z-idex-search-ags,
z-marketplace-dashboard,
z-commodities-modal,
z-export-details,
z-insurance-only-shipment,
z-user-preferences,
z-schedule-pickup-form,
z-claim-damage,
z-customer-details,
z-address-form,
z-jewelry-details,
z-watch-details,
z-colored-stone-details,
z-diamond-details,
z-add-diamond,
z-pearl-details,
z-preview-appraisal,
z-valuation,
z-email-preview,
z-save-draft-modal,
z-appraisal-confirm-modal,
z-shipping-dashboard,
z-self-register,
z-upload-certificate-modal,
z-upload-certificate-modal-v2,
z-exemption-preview-modal,
z-base-shipment-location,
z-one-page-shipment,
z-armored-transport,
z-address-validation-confirmation,
z-shipment-reconciliations-listing,
z-buyer-details,
z-buyer-details,
z-payment-details,
z-jewelry-item-modal,
z-shipped-memo-modal,
z-edit-estore,
z-edit-woocommerce-estore,
z-estore-origin-defaults,
z-estore-general-defaults,
z-estore-shipment-methods,
z-ecom-estore-modal,
z-estore-defaults,
z-memo-details,
z-payment-method-modal,
z-return-items-modal,
z-new-order,
z-shipping-billing,
z-jeweler-pages-configure,
z-jmcc-register-modal,
z-jmcc-dashboard-page,
z-jmcc-contact-us-modal,
z-address-search,
.marketplace-page {
  .zing-ui {
    @import './ng-select';
  }
}

// remove accessiblity per Joe Jennings

 .zing-ui a,button, input, select, textarea, .form-control, .zing-ui button {
  &:focus {
  outline: none !important;
  }
 }

@import './zing-ui-modal';

.zing-ui .btn-circle i {
  transform: translate(-50%, -50%) !important;
}

.zing-ui .ng-select .ng-select-container, .ng-select .zing-ui .ng-select-container {
  padding: 0.45rem 1.5rem 0.45rem 0.6rem;
}

.zing-ui .form-group .input-group.prepend .input-group-prepend select, .zing-ui .form-group .input-group .form-control{
  height: calc(2.25rem + 2px) !important;
}
