@mixin zing-button-base($bg-color, $font-color) {
  background-color: $bg-color !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  color: $font-color !important;
  border-radius: 2rem !important;
  padding: 0.75rem 1rem !important;
}

@mixin zing-button-primary($bg-color, $font-color) {
  @include zing-button-base($bg-color, $font-color);
    border: 1px solid $bg-color;

  &:hover {
    background-color: $font-color !important;
    color: $bg-color !important;
  }
}

@mixin zing-button-outlined($primary-color, $bg-color) {
  @include zing-button-base($bg-color, $primary-color);
  border: 1px solid $primary-color;

  
  &:hover {
    background-color: $primary-color !important;
    color: $bg-color !important;
  }
}