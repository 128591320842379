@import "src/styles/_scss/utils/_variables.scss";

z-idex-search,
z-idex-search-ags,
z-marketplace-dashboard {
  .form-control::placeholder {
    color: $color-placeholder !important;
    opacity: 1;
  }

  .form-control::-webkit-input-placeholder /* Chrome/Opera/Safari */ {
    color: $color-placeholder !important;
    opacity: 1;
  }

  .form-control::-moz-placeholder /* Firefox 19+ */ {
    color: $color-placeholder !important;
    opacity: 1;
  }

  .form-control:-ms-input-placeholder /* IE 10+ */ {
    color: $color-placeholder !important;
    opacity: 1;
  }

  .form-control::-ms-input-placeholder /* Edge */ {
    color: $color-placeholder !important;
    opacity: 1;
  }

  .form-control:-moz-placeholder /* Firefox 18- */ {
    color: $color-placeholder !important;
    opacity: 1;
  }

  .form-control:-ms-input-placeholder /* IE 10+ */ {
    color: $color-placeholder !important;
    opacity: 1;
  }

  .condensed-bold {
    font-family: "RobotoCondensed-Bold";
    font-size: 12px;
    line-height: 16px;
    color: $color-text-1;
  }

  .condensed-regular {
    font-family: "RobotoCondensed-Regular";
    line-height: 16px;
  }

  .condensed-bold-italic-14 {
    font-family: RobotoCondensed-BoldItalic, "Roboto Condensed Bold Italic", "Roboto Condensed", sans-serif;
    font-size: 14px;
    line-height: normal;
    color: $color-text-8;
  }

  .medium-italic-22 {
    font-family: "Roboto-MediumItalic";
    font-size: 22px;
    line-height: normal;
    color: $color-text-1;
  }

  .medium-italic-16 {
    font-family: "Roboto-MediumItalic";
    font-size: 16px;
    line-height: normal;
    color: $color-text-1;
  }

  .italic-12 {
    font-family: "Roboto-Italic";
    font-size: 12px;
    color: $color-text-5;
  }

  .w-mc {
    width: min-content;
  }

  .zing-mutliselect {
    width: 60%;
  }

  .zing-link {
    font-family: RobotoCondensed-BoldItalic, "Roboto Condensed Bold Italic", "Roboto Condensed", sans-serif;
    font-size: 14px;
    text-decoration: underline !important;
    color: $color-link !important;
  }

  .search-item-controls .search-row {
    padding: 0 5px;
    margin-bottom: 5px;
    display: flex;
    align-items: baseline;
  }

  .ng-select .ng-select-container {
    font-size: 12px;
    border-color: gray;
  }

  .ng-select.dropdown-left {
    display: flex;

    .ng-dropdown-panel {
      width: auto;
      left: auto;
      right: 0;
    }
  }

  .ng-select.dropdown-bottom {
    .ng-dropdown-panel .ng-dropdown-panel-items {
      max-height: 130px !important;
    }
  }

  .ng-select.dropdown-right {
    display: flex;

    .ng-dropdown-panel {
      width: auto;
    }
  }

  .no-selection {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
  }

  .order-status-grid {
    kendo-pager {
      display: grid;
      grid-template-columns: 1fr auto 1fr;

      .page-links {
        display: flex;
        grid-column-start: 2;
      }
      .page-info {
        grid-column-start: 3;
        margin-left: auto;
        margin-right: 0px;
      }
    }
    @media (max-width: 992px) {
      kendo-pager {
        display: flex;

        .page-links {
          margin: auto;
          margin-bottom: 2em;
        }
        .page-info {
          margin: auto !important;
        }
      }
    }

    @media (max-width: 1024px) {
      .order-status-search.form-group {
        margin-left: 0 !important;
      }
    }

    .order-id-link,
    .tracking-number-link {
      text-decoration: underline !important;
      font-weight: 400 !important;
    }

    .status-icon {
      width: 1em;
      margin-left: 0.4em;
    }

    .delivered {
      color: $color-green-success;
      font-family: Roboto-Bold;
    }

    .cancelled {
      color: #990001;
      font-family: Roboto-Bold;
    }

    .other {
      color: $color-green-success;
      font-family: Roboto-Bold;
    }
  }

  .show > .dropdown-toggle {
    color: $color-white !important;
    background-color: $color-zing-btn-outline;
    border-color: $color-zing-btn-outline;
  }
}

.flex-auto {
  flex: auto;
  flex: 1;
}

.w-30 {
  width: 30%;
}

// TODO: Remove when all alerts are updated with zing-ui-alert class
.order-complete,
.order-details,
.shopping-cart,
.payment-details,
.order-status-grid {
  // override style from global _alerts.scss
  .alert {
    // global _alerts.scss adds font icon using pseudo before element
    &::before {
      content: none;
    }

    // change to default webkit values
    font-size: 0.9rem;
    letter-spacing: normal;
    font-weight: 400;
    line-height: 1.5;
  }
}

.zing-ui-alert {
  // global _alerts.scss adds font icon using pseudo before element
  &::before {
    content: none !important;
  }

  // change to default webkit values
  font-size: 0.9rem;
  letter-spacing: normal;
  font-weight: 400;
  line-height: 1.5;

  &.page-error {
    margin-top: 0.1rem;
    margin-bottom: 0 !important;
  }
}

.marketplace-dashboard,
.order-complete,
.order-details,
.payment-details,
.shopping-cart {
  background-color: $color-base;
  max-width: 1440px;

  .content-page {
    padding: 32px 12px 65px;
  }

  .title {
    font-size: 54px;
    font-family: "RobotoCondensed-Light";
    line-height: 63px;
    margin-bottom: 3.25rem;
  }

  .alert-icon {
    color: $notification-blue;
  }
}

.tax-banners-qualified {
  background-color: $color-banner-green !important;
  color: $color-green-success-message !important;
  line-height: 1rem;
  border-radius: .5rem !important;
  font-weight: 400 !important;

  .icon {
    color: $color-green-success-message !important;  
  }

  .btn-link {
    color: $color-green-success-message !important;
  }

  .btn-link:hover {
    font-weight: 700;
    color: $color-green-success-message !important;
  }

  .btn-link:active {
    box-shadow: none !important;
  }

  .btn-link:focus {
    box-shadow: none !important;
  }

  .btn-outline-info {
    font-weight: 400;
    margin: 8px 24px !important;
    border-radius: 6.19rem !important;
    color: $color-green-success-message !important;
    border: 1px solid $color-green-success-message !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  
  .btn-outline-info:hover {
    color: $color-white !important;
    background-color: $color-green-success-message !important;
    font-family: "Roboto", Roboto, sans-serif;
  }

  .btn-outline-info:active {
    color: $color-white !important;  
    background-color: $color-green-success-message !important;
  }

  .btn-outline-info:not(:hover) {
    background-color: $color-white;
    color: $color-zing-link;
    font-family: "Roboto", Roboto, sans-serif;
  }

  @media only screen and (max-width: 992px) {
    .btn-outline-info {
      padding-left: 0.75rem !important;
      padding-right: 0.75rem !important;
    }
  }
}

.tax-banner-buttons {

  .btn-link {
    color: $color-tax-banner-link;
    border: transparent;
  }

  .btn-link:hover {
    font-weight: 700;
  }

  .btn-link:active {
    box-shadow: none !important;
  }

  .btn-link:focus {
    box-shadow: none !important;
  }

  .btn-outline-info:active {
    color: $color-white !important;
    background-color: $color-tax-banner-link !important;
  }

  .btn-outline-info {
    color: $color-tax-banner-link;
    margin: 8px 24px !important;
    border-radius: 6.19rem !important;
    border: 1px solid $color-tax-banner-link !important;
    font-weight: 400;
    font-family: "Roboto", Roboto, sans-serif;
    background-color: $color-white;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .btn-outline-info:hover {
    background-color: $color-tax-banner-link;
    color: $color-white;
    border-color: $color-tax-banner-link;
    font-family: "Roboto", Roboto, sans-serif;
  }

  @media only screen and (max-width: 992px) {
    .btn-outline-info {
      padding-left: 0.75rem !important;
      padding-right: 0.75rem !important;
    }
  }
}

.tax-banners-error {
  background-color: $color-red-error !important;
  color: $color-white !important;

  @media only screen and (max-width: 992px) {
    display: flex;
  }
}

.tax-banners {
  background-color: $color-banner-yellow;
  color: $color-tax-banner-link;
  margin-top: 0.1rem !important;
  border-radius: .5rem !important;
  padding: 1rem !important;

  .info {
    font-weight: 400 !important;
  }

  .title {
    font-weight: 700 !important;
  }

  .icon {
    line-height: 1rem;
  }

  @media only screen and (max-width: 992px) {
    .btn-container {
      min-width: max-content;
      margin-top: 0.5rem;
      margin-left: 1rem;
    }

    .msg {
      margin-left: 2rem !important;      
    }

    .msg-container {
      margin-left: 0 !important;
    }
  }
}

.marketplace-page {
  background-color: $color-base;
  max-width: 1440px;

  .container-fluid {
    padding: 0 1.5rem;
  }

  .content-page {
    padding: 0;
    margin-left: 0;
  }

  .text-primary {
    color: #28395b !important;
  }

  .line-height-normal {
    line-height: normal;
  }

  .custom-control-label {
    line-height: 24px;
  }

  .title-container {
    margin-bottom: 2rem;
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;

    .title {
      font-size: 3.37rem;
      font-family: RobotoCondensed-Light;
      line-height: 3.94rem;
      margin-right: 2rem;
    }

    @media (max-width: 640px) {
      .title {
        font-size: 2.5rem !important;
        width: 100%;
      }
    }
  }

  .form-control {
    box-shadow: none;
  }
}

.checkbox-min-width {
  min-width: 7.5rem !important;
}

.marketplace-checkbox-label {
  line-height: 24px !important;
  font-weight: normal !important;
}

.text-gray {
  color: $color-text-10;
}

.text-green {
  color: $color-green-success-message;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.modal-border-radius-15 {
  .modal-content {
    border-radius: 15px;
  }
}

.modal-width-mobile {
  width: 80% !important;
  .modal-content {
    margin: 0;
  }

  @media (max-width: 420px) {
    margin-left: auto;
    margin-right: auto;
  }
}
