//List of Roboto fonts

@font-face {
  font-family: 'Roboto-Light';
  src: url('../../../assets/fonts/Roboto/Roboto-Light.woff') format('woff'),
      url('../../../assets/fonts/Roboto/Roboto-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../../../assets/fonts/Roboto/Roboto-Regular.woff') format('woff'),
      url('../../../assets/fonts/Roboto/Roboto-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Roboto-Italic';
  src: url('../../../assets/fonts/Roboto/Roboto-Italic.woff') format('woff'),
      url('../../../assets/fonts/Roboto/Roboto-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../../../assets/fonts/Roboto/Roboto-Medium.woff') format('woff'),
      url('../../../assets/fonts/Roboto/Roboto-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('../../../assets/fonts/Roboto/Roboto-Bold.woff') format('woff'),
      url('../../../assets/fonts/Roboto/Roboto-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'RobotoCondensed-Regular';
  src: url('../../../assets/fonts/Roboto/RobotoCondensed-Regular.woff') format('woff'),
      url('../../../assets/fonts/Roboto/RobotoCondensed-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'RobotoCondensed-Light';
  src: url('../../../assets/fonts/Roboto/RobotoCondensed-Light.woff') format('woff'),
      url('../../../assets/fonts/Roboto/RobotoCondensed-Light.otf') format('opentype'),
      url('../../../assets/fonts/Roboto/RobotoCondensed-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'RobotoCondensed-BoldItalic';
  font-weight: 700;
  font-style: italic;
  src: url('../../../assets/fonts/Roboto/RobotoCondensed-BoldItalic.woff') format('woff'),
      url('../../../assets/fonts/Roboto/RobotoCondensed-BoldItalic.otf') format('opentype');
}


@font-face {
  font-family: 'dripicons-v2';
  src: url('../../../assets/fonts/dripicons-v2.woff') format('woff');
}

// TODO: WEBKIT remove once issue on webkit is fixed
@font-face {
  font-family: 'unicons'; 
  src: url('../../../assets/fonts/unicons.woff') format('woff');
}

@font-face {
  font-family: 'RobotoCondensed-Bold';
  src: url('../../../assets/fonts/Roboto/RobotoCondensed-Bold.woff') format('woff'),
      url('../../../assets/fonts/Roboto/RobotoCondensed-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Roboto-MediumItalic';
  src: url('../../../assets/fonts/Roboto/Roboto-MediumItalic.woff') format('woff'),
      url('../../../assets/fonts/Roboto/Roboto-MediumItalic.otf') format('opentype');
}

@font-face {
  font-family: 'RobotoCondensed-Italic';
  src: url('../../../assets/fonts/Roboto/RobotoCondensed-Italic.woff') format('woff'),
      url('../../../assets/fonts/Roboto/RobotoCondensed-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url('../../../assets/fonts/Roboto/Roboto-BoldItalic.woff') format('woff'),
      url('../../../assets/fonts/Roboto/Roboto-BoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'unicons';
  src: url('../../../assets/fonts/unicons.woff') format('woff');
}

@font-face {
  font-family: 'Material Design Icons';
  src: url('../../../assets/fonts/materialdesignicons-webfont.woff2') format('woff');
}