@import '../utils/_variables.scss';

#dateRangeForm {
    .ngb-dp-header {
        background-color: $color-white !important;
    }
    .ngb-dp-weekdays {
        background-color: $color-white !important;
    }
    .form-group .form-select, .form-group .ng-select .ng-select-container, .ng-select .form-group .ng-select-container {
        height: 1.85rem !important;
    }
}

// Overriding default datepicker shadow to shadow from version 6
.mat-datepicker-content {
    box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12) !important;
}