@import "src/styles/_scss/utils/_variables.scss";

.marketplace-modal {
    @media (max-width: 600px) {
        .modal-content {
            width: 95%;
        }

        .compare-modal-footer.modal-footer {
            margin-top: 1rem !important;
        }  
    }
    
    .modal-content {
        box-shadow: none;
        padding: 0 !important;
    }

    .header-bottom-border {
        margin: 0 !important;
    }

    .compare-modal-footer.modal-footer {
        position: relative;
        z-index: 10;
    }
}

.modal-shadow {
    .modal-content {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5) !important;
    }
}

body.modal-open {
    &.cart-modal-open {
        .modal-backdrop,
        .modal {
            z-index: 10000;
        }
        .modal-backdrop {
            background-color: $color-black;
            &.show,
            &.fade {
                opacity: 0.65;
            }
        }
        .member-layout {
            filter: none;
        }
    }
}

.fees-info-subtitle {
    color: $color-title-dark-blue;
    font-size: 17px;
}

.fees-info-details {
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}
