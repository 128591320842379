@import "../utils/_variables.scss";

.zing-slider {
  padding-right: 5px;
  padding-left: 5px;

  $handleBgColor: #3a5277 !default;
  $handleDisabledColor: #d8e0f3 !default;
  $barFillColor: $color-slider-dark-blue !default;
  $barDisabledFillColor: #8b91a2 !default;
  $barLeftOutSelectionColor: $barDisabledFillColor !default;
  $barRightOutSelectionColor: #03a688 !default;
  $color-slider-label: #55637d;

  $ticksWidth: 5px !default;

  $handleSize: 18px !default;
  $labelFontSize: 8px !default;
  $barDimension: 12px !default;

  @mixin rounded($radius: 2px) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
  }

  .ngx-slider {
    margin: 0px !important;

    .ngx-slider-ceil,
    .ngx-slider-floor {
      display: none !important;
    }

    .ngx-slider-bar {
      height: $barDimension !important;
      border-radius: none !important;
    }

    .ngx-slider-left-out-selection {
      .ngx-slider-bar {
        background: $handleDisabledColor !important;
        visibility: visible !important;
      }
    }
    .ngx-slider-right-out-selection {
      .ngx-slider-bar {
        background: $handleDisabledColor !important;
        visibility: visible !important;
      }
    }

    .ngx-slider-selection {
      z-index: 2;
      background: $barFillColor;
    }

    .ngx-slider-pointer {
      width: 8px !important;
      height: $handleSize !important;
      top: -3px !important;
      background-color: $handleBgColor !important;
      border: 1px solid $handleBgColor !important;
      border-radius: 0 !important;

      &:after {
        display: none;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.2);
      }
    }

    .ngx-slider-bubble {
      cursor: default;
      bottom: 9px !important;
      padding: 2px 5px;
      background: $color-slider-label;
      border-radius: 3px;
      color: $color-white;
      font-size: 12.8px;
      margin-bottom: 6px;
      min-width: 20px;
      text-align: center;
      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        width: 0;
        height: 0;
        border-top: solid 5px $color-slider-label;
        border-left: solid 5px transparent;
        border-right: solid 5px transparent;
      }
    }

    .ngx-slider-tick-value.ngx-slider-span {
      top: 13px !important;
      left: 0 !important;
    }

    .ngx-slider-ticks,
    .ngx-slider-tick-value {
      top: 5px !important;
      left: 0px !important;
      .ngx-slider-tick,
      .ngx-slider-tick-value {
        width: 0px;
        height: 0px;

        margin-left: 0 !important;

        .ngx-slider-tick-legend,
        .ngx-slider-inner-tooltip {
          top: 13px !important;
          white-space: nowrap !important;
          font-family: "RobotoCondensed-Bold";
          font-size: 12px;
          -webkit-transform: translate(-60%, 0);
          transform: translate(-60%, 0);
          @media (max-width: 461px) {
            font-size: 10px;
          }
        }

        &.ngx-slider-selected-limit .ngx-slider-tick-legend {
          font-family: "RobotoCondensed-Bold";
          font-weight: 700;
          color: $color-header-blue;
        }
      }
      .ngx-slider-tick:first-child {
        .ngx-slider-tick-legend,
        .ngx-slider-inner-tooltip {
          transform: unset !important;
        }
      }

      .ngx-slider-tick:last-child {
        .ngx-slider-tick-legend,
        .ngx-slider-inner-tooltip {
          -webkit-transform: translate(-90%, 0);
          transform: translate(-90%, 0);
        }
      }
    }

    .ngx-slider-tick:not(:first-child) .ngx-slider-tick-value .ngx-slider-inner-tooltip {
      margin-left: -17px;
    }

    .ngx-slider-tick:last-child .ngx-slider-tick-value .ngx-slider-inner-tooltip {
      margin-left: -20px;
    }

    .ngx-slider-tick-value-number {
      font-family: "RobotoCondensed-Regular";
      font-weight: normal;
    }
  }
}

.zing-slider-v2 {
  $handleBgColor: #3a5277 !default;
  $handleDisabledColor: #d8e0f3 !default;
  $barFillColor: $color-slider-dark-blue !default;
  $color-slider-label: #55637d;
  $barDimension: 2px !default;

  min-height: 64px;

  .ngx-slider {
    margin: 20px 0 15px;

    .ngx-slider-bar {
      height: $barDimension;
    }

    .ngx-slider-left-out-selection,
    .ngx-slider-right-out-selection {
      .ngx-slider-bar {
        background: $handleDisabledColor;
      }
    }

    .ngx-slider-selection {
      background: $barFillColor;
    }

    .ngx-slider-pointer {
      width: 16px !important;
      height: 16px !important;
      top: -6px !important;
      background-color: $handleBgColor;
      border-radius: 50%;

      &:after {
        display: none;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.2);
      }
    }

    .ngx-slider-tick {
      height: 0 !important;
    }

    .ngx-slider-ticks {
      left: -6px;
    }

    .ngx-slider-tick-legend {
      font-size: 11px;
      white-space: nowrap;
    }

    .ngx-slider-ticks,
    .ngx-slider-tick-value {

      .ngx-slider-tick,
      .ngx-slider-tick-value { 
        .ngx-slider-tick-legend,
        .ngx-slider-inner-tooltip {
          font-size: 12px;
        }
      }

      .ngx-slider-tick:last-child {
        .ngx-slider-tick-legend,
        .ngx-slider-inner-tooltip {
          -webkit-transform: translate(-40%, 0);
          transform: translate(-40%, 0);
        }
      }
    }

    .ngx-slider-span.ngx-slider-model-high {
      left: 0px !important;
    }
  }
}
