.attention-zoom-animation {
    -webkit-animation: zoom-in-zoom-out 0.3s ease-out forwards;
    animation: zoom-in-zoom-out 0.3s ease-out forwards;
  }
  
  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.05, 1.05);
    }
    100% {
      transform: scale(1, 1);
    }
  }