@import "src/styles/_scss/utils/variables";

.ng-select-container {  
  box-shadow: none !important;
  height: fit-content !important;
  display: flex !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  min-height: 38px !important;
  background: #fff url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='50' fill='%238A8A8A'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat right 0.75rem top 16px/11px 7px !important;

  .ng-value-container {
    padding: 0 !important;
    height: 100%;
    input:focus, input:active {
      box-shadow: none !important;
    }
    .ng-placeholder {      
      padding-bottom: 0 !important;
      top: auto !important;
      bottom: auto;
    }

    .ng-value {      
      margin-bottom: 0 !important;
    }
  }

  .ng-clear-wrapper {
    margin-top: auto;
    margin-bottom: auto;

    .ng-clear {
      vertical-align: middle;
    }
  }
}

.ng-dropdown-panel {
  .scroll-host {
    &::-webkit-scrollbar-thumb {
      background-color: $color-slider-dark-blue !important;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }
  }

  .ng-dropdown-panel-items {

    .ng-option.ng-option-selected,
    .ng-option.ng-option-selected.ng-option-marked {
      background-color: $color-slider-dark-blue !important;
      color: $color-white !important;

      .ng-option-label {
        font-weight: normal !important;
      }
    }
  }
}

.ng-select-single {
  .ng-select-container {
    min-width: fit-content;
  }
}

z-shipment-method-defaults {
  .ng-select-single {
    .ng-select-container {
      min-width: auto;
    }
  }
}

.cod-payment-type {
  .ng-select-single {
    .ng-select-container {
      padding-right: 1rem;
      min-width: auto;
    }
  }
}

.cart-details {
  .ng-option {
    border-bottom: 1px solid $color-border-grey !important;
  }
}

.ng-select-multiple {
  .ng-value-container {
    .ng-value {
      background-color: $color-slider-dark-blue !important;
      color: $color-white !important;
      .ng-value-icon.left {
        border-color: $color-slider-dark-blue !important;

        &:focus,
        &:hover {
          background-color: $color-slider-dark-blue !important;
        }
      }
    }
  }
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: $color-ruby;
}

ng-select.ng-select-disabled {
  .ng-select-container {
    background: #e9ecef !important;
  }
}
