@import './_fonts.scss';
@import './_variables.scss';
.proxi-bold {
    font-family: Roboto-Bold;
}

.separation-line {
    border-top: 1px solid $color-text-4;
}

.profile-content {
    border-radius: 0 10px 10px 0;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    padding: 2.5em;
    height: 100%;
    .heading-20-bold-primary {
        span {
            float: right;
            img {
                height: 0.8em;
                cursor: pointer;
            }
        }
    }
}

@media(max-width: 991.98px) {
    .profile-content {
		padding: 1em;
		margin: 1em;
		background-color: $color-white;
		border-radius: 5px;
    }
}

.cross-sign {
    border-radius: 50%;
    background-color: $color-primary;
    height: 2em;
    opacity: 0.8;
    width: 2em;
    display: inline-block;
    cursor: pointer;
    span {
        color: $color-white;
        font-size: 18px;
        display: table;
        margin: 6px auto;
    }
}

.input-bubble {
	border: 1px solid transparent;
	border-left-color: $color-primary;
	border-right-color: $color-turquoise;
	overflow: overlay;
    background: none;
    background-image: linear-gradient(to right, $color-primary 0%, $color-turquoise 100%);
    border-radius: 40px;
    color: $color-primary;
    display: inline-block;
    margin: 0 1em 0.5em 0;
    .inner-bubble {
        background: white;
        border-radius: 40px;
        display: inline-block;
        padding: 0.7em 1em;
        span {
            cursor: pointer;
            padding-left: 0.5em;
			white-space: nowrap;
        }
    }
}

.cross-circle-sign {
    border-radius: 50%;
    background-color: white;
    height: 0.8em;
    position: absolute;
    right: 12px;
    top: 13px;
	width: 0.8em;
    &::before {
        color: $color-primary;
        content: "+";
        display: flex;
        font-family: serif;
        font-size: 19px;
        justify-content: center;
		transform: rotate(45deg);
		margin-top: -1px;
    }
}

.resend-text {
  cursor: pointer;
  color: $color-primary
}

.business-unit-block {
	.form-group {
		label {
			display: block;
		}
		.form-control {
			display: inline-block;
		}
	}
	
	.plus-sign, .minus-sign {
		border-radius: 50%;
		background-color: $color-primary;
		height: 3em;
		position: absolute;
		top: 2.1em;
		width: 3em;
		margin-left: 2em;
		cursor: pointer;
		display: none;
		&::before {
			color: $color-white;
			content: "+";
			display: flex;
			font-family: Roboto-Light;
			font-size: 39px;
			justify-content: center;
			margin: 12px auto;
		}
	}

	.minus-sign {
		margin-left: 6em;
		&::before {
			content: "-";
			font-size: 64px;
			margin: 10px auto;
		}
	}
	
	.row:last-child {
		.plus-sign, .minus-sign {
			display: inline-block;
		}
	}

	.child-unit:nth-child(2) {
		.form-group {
			margin-left: 4em;
		}
	}
	.child-unit:nth-child(3) {
		.form-group {
			margin-left: 8em;
		}
	}
	.child-unit:nth-child(4) {
		.form-group {
			margin-left: 12em;
		}
	}
	.child-unit {
		.form-group::before, .form-group::after {
			content: "";
			position: absolute;
			left: -12px;
		}
		.form-group::before {
			border-top: 1px dashed #000;
			top: 50px;
			width: 25px;
		}
		.form-group::after {
			border-left: 1px dashed #000;
			height: 84%;
			width: 0px;
			top: -17px;
		}
	}
}

.alert-ribbon {
    background-color: rgba(97, 94, 155, 0.7);
	padding: 0.6em;
    color: $color-base-2;
    font-family: Roboto-Light;

    .alert-message {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}

.blue-text {
	color: $color-zing-btn-modal-blue;
}

@media(max-width: 991.98px) {
    .alert-ribbon {
        .alert-message {
            padding-bottom: 1em;
        }
    }
}

@media(max-width: 991.98px){ 
	.business-unit-block {
		.plus-sign, .minus-sign {
			height: 1.5em;
			width: 1.5em;
			font-size: 20px;
			top: 1.8em;
			left: 8em;
			&::before {
				margin: 7px auto;
				font-size: 33px;
			}
		}
		.minus-sign {
			left: 5.6em;
			&::before {
				font-size: 54px;
				margin: 6px auto;
			}
		}
		.child-unit:nth-child(2) {
			.form-group {
				margin-left: 3em;
			}
		}
		.child-unit:nth-child(3) {
			.form-group {
				margin-left: 6em;
			}
		}
		.child-unit:nth-child(4) {
			.form-group {
				margin-left: 9em;
			}
		}
	}
}


.insurance-block {
	.accordion {
		.card {
			box-shadow: 0 4px 9px 0 #E6E6E6;
			border: none;
			.card-header {
				margin-bottom: 0;
				border-bottom: 0;
				border-radius: 0;
				background-color: rgba(250,250,252,0.6);
				padding: 1.5em;
				.btn-link {
					text-decoration: none;
					box-shadow: none;
					text-align: left;
					padding: 0;
					background-color: rgba(250,250,252,0.6);
					font-family: Roboto-Light;
					text-transform: capitalize;
					.label {
						color: $color-primary;
						font-size: 18px;
						line-height: 16px;
					}
					.number {
						color: $color-blue;
						font-weight: 300;
						line-height: 20px;
					}
					.status {
						color: $color-emerald;
						font-size: 16px;
						line-height: 20px;
						position: absolute;
						top: 1em;
						right: 2em;
					}
				}
			}
			.card-body {
				font-family: Roboto-Light;
				.title {
					color: $color-text-3;
					font-size: 14px;
					line-height: 16px;
				}
				.value {
					color: $color-blue;
					font-size: 16px;
					line-height: 19px;
				}
			}
		}
	}
}

.location-pointer-img {
	height: 1.7em;
}

.cursor-pointer {
	cursor: pointer;
}

.heading-bar {
	box-sizing: border-box;
	width: 80px;
	border: 2px solid #796BA8;
	display: block;
	&.white {
	  border: 2px solid #F4F4F4;
	}
  }
  
  @media (max-width: 991.98px) {
	.heading-bar {
	  width: 45px;
	}
  }

  @media(max-width: 992px) {
	.shipment-btn-wrap {
	  .save-exit-wrap {
		  margin-top: 15px;
		  order: 2;
	  }
	}
  }